import { Image } from '@mui/icons-material'
import { ColorPicker } from 'components/Input/ColorPicker'
import { ColorPickers, InputHeader, InputWrapper, Spacer } from './components'

export const DesignTab = () => (
  <>
    <InputHeader>Colors</InputHeader>
    <ColorPickers>
      <ColorPicker label="Questions" onChange={(color: any) => console.log(color)} />
      <ColorPicker label="Answers" onChange={(color: any) => console.log(color)} />
      <ColorPicker label="Buttons" defaultValue="#11afda" onChange={(color: any) => console.log(color)} />
      <ColorPicker label="Button text" onChange={(color: any) => console.log(color)} />
      <ColorPicker label="Background" defaultValue="#122a54" onChange={(color: any) => console.log(color)} />
    </ColorPickers>

    <Spacer />

    <InputWrapper>
      <InputHeader>
        <Image sx={{ display: 'inline-block', verticalAlign: 'middle', color: '#aaa' }} />
        <span style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 4 }}>Background image</span>
      </InputHeader>
      <InputHeader style={{ fontWeight: 'bold', cursor: 'pointer' }}>+Add</InputHeader>
    </InputWrapper>

    <Spacer />

    <InputWrapper>
      <InputHeader>
        <Image sx={{ display: 'inline-block', verticalAlign: 'middle', color: '#aaa' }} />
        <span style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: 4 }}>Brand Logo</span>
      </InputHeader>
      <InputHeader style={{ fontWeight: 'bold', cursor: 'pointer' }}>+Add</InputHeader>
    </InputWrapper>
  </>
)
