import { NavItem, Topbar } from 'components/Elements/Topbar'
import { AccountMenu } from 'components/UI/AccountMenu'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Profile } from './Profile'
import { Settings } from './Settings'

export const Account = () => {
  return (
    <>
      <Topbar right={<AccountMenu />}>
        <NavItem path="personal">profile</NavItem>
        <NavItem path="business">settings</NavItem>
      </Topbar>
      <Routes>
        <Route path="/personal" element={<Profile />} />
        <Route path="/business" element={<Settings />} />
        <Route path="/" element={<Navigate to="personal" />} />
      </Routes>
    </>
  )
}
