import { useAppDispatch } from 'app/hooks'
import { RootState } from 'app/store'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { asyncConfirmCode, asyncSignIn, clearCredentials } from 'services/authSlice'
import { useCreateClientMutation } from 'services/userApi'
import { Input, LoginBox, Name, Subheader, Submit } from './LoginComponents'

export const ConfirmEmail = () => {
  const [createClient] = useCreateClientMutation()
  const { username, password, firstName, lastName } = useSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const [code, setCode] = useState<string>('')
  const [codeStatus, setCodeStatus] = useState<'default' | 'entering' | 'valid'>('default')

  useEffect(() => {
    const newStatus = code.length === 0 ? 'default' : code.length === 6 ? 'valid' : 'entering'
    setCodeStatus(newStatus)
  }, [code])

  console.log(firstName + ' ' + lastName)

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    const { from } = (location.state as any) || { from: { pathname: '/' } }
    if (code.length !== 6) return alert('Please make sure your verification code is correct.')
    if (!username || !password) return alert('Error: credentials not found, please try again')
    dispatch(asyncConfirmCode({ code, username }))
      .then((result) => {
        console.log(result)
        dispatch(asyncSignIn({ username, password }))
          .then(async (result: any) => {
            dispatch(clearCredentials())
            if (result.error) return alert(`Error: ${result.error.message}`)
            await createClient({ firstName, lastName, email: username })
            navigate(from.pathname)
          })
          .catch((e) => {
            alert(
              `Unable to sign in at this time. Please contact us if this issue persists.\n\nUnknown error: ${e}`
            )
          })
      })
      .catch((e) => {
        console.error(e)
      })
  }

  return (
    <LoginBox>
      <Name>BWI</Name>
      <Subheader>Please enter the code that we sent to your email</Subheader>
      <form autoComplete="off" spellCheck="false">
        <Input
          key="code"
          status={codeStatus}
          placeholder="Verification code"
          autoComplete="none"
          value={code}
          onChange={(e) => setCode(e.target.value.trim())}
        />
        <Submit type="submit" value="Confirm code" onClick={handleSubmit} />
      </form>
    </LoginBox>
  )
}
