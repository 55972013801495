import { baseApi } from './baseApi'

const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getQuestions: build.query<Question[], void>({
      query: () => ({
        url: `question/`,
        method: 'GET',
      }),
      providesTags: ['question'],
    }),

    getQuestion: build.query<Question, string>({
      query: (_id) => ({
        url: `question/${_id}`,
        method: 'GET',
      }),
      providesTags: ['question'],
    }),

    putQuestion: build.mutation({
      query: ({ _id, delta }) => ({
        url: `question/${_id}`,
        method: 'PUT',
        body: delta,
      }),
      invalidatesTags: ['question', 'survey'],
    }),

    postQuestion: build.mutation<Question, Question>({
      query: (question) => ({
        url: `question/`,
        method: 'POST',
        body: question,
      }),
      invalidatesTags: ['question', 'survey'],
    }),

    deleteQuestion: build.mutation<void, { _id: string }>({
      query: ({ _id }) => ({
        url: `question/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['question', 'survey'],
    }),
  }),
})

export const {
  useGetQuestionsQuery,
  useGetQuestionQuery,
  usePutQuestionMutation,
  usePostQuestionMutation,
  useDeleteQuestionMutation,
} = api
