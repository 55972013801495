import { baseApi } from './baseApi';

const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getS3SignedUrl: build.mutation<{ signedRequest: string; resourceUrl: string }, void>({
      query: () => ({
        url: `util/s3-signed-url`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetS3SignedUrlMutation } = api
