import { useState } from 'react'
import styled from 'styled-components'

export const DragShenanigans = ({ background }: { background?: string }) => {
  const DropZone = ({ children, onDrop }: { children?: any; onDrop?: any }) => {
    const [hovered, setHovered] = useState(false)

    return (
      <StyledDropZone
        hovered={hovered}
        onDrop={() => {
          if (onDrop) {
            onDrop()
          }
          setHovered(false)
        }}
        onDragEnter={() => setHovered(true)}
        onDragLeave={() => setHovered(false)}
        onDragOver={(event) => event.preventDefault()}
      >
        <IgnoreMouseEvents>{children}</IgnoreMouseEvents>
      </StyledDropZone>
    )
  }

  return (
    <StylishShenanigans>
      <Background image={background} />
      <Header>Who will win the Stanley Cup?</Header>
      <DropZone
        onDrop={() => {
          alert('Score! Your vote has been cast')
        }}
      >
        <DropZoneLabel>Drag a team's puck here to vote!</DropZoneLabel>
      </DropZone>
      <DraggableChoices>
        <HockeyPuck draggable={true} onDrop={() => console.log('test')}>
          <HockeyPuckLogo src="/img/temp/panthers_logo.svg" />
        </HockeyPuck>

        <HockeyPuck draggable={true}>
          <HockeyPuckLogo src="/img/temp/avalanche_logo.svg" />
        </HockeyPuck>

        <HockeyPuck draggable={true}>
          <HockeyPuckLogo src="/img/temp/hurricanes_logo.svg" />
        </HockeyPuck>

        <HockeyPuck draggable={true}>
          <HockeyPuckLogo src="/img/temp/maple_leafs_logo.svg" style={{ width: '55%' }} />
        </HockeyPuck>
      </DraggableChoices>
    </StylishShenanigans>
  )
}

const StylishShenanigans = styled.div``

const Header = styled.h1`
  position: relative;
  margin: 220px auto 40px;
  text-align: center;
  color: white;
  text-shadow: 0px 0px 6px #000a;
  font-size: 50px;
`

const Footer = styled.p`
  position: fixed;
  text-align: center;
  bottom: 40px;
  width: 100%;
  color: white;
  text-shadow: 0 0 4px black;
`

const DraggableChoices = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  padding-bottom: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
`

const Background = styled.div<{ image?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* filter: blur(4px) brightness(0.8); */

  background: ${({ image }) => (image ? `url(${image})` : 'unset')};
  background-size: cover;
`

const HockeyPuck = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  border-bottom: 20px solid #1a1a1a;
  padding-bottom: 10px;
  height: 110px;
  width: 160px;
  z-index: 9;
  background-image: radial-gradient(rgba(34, 50, 84, 0.24), rgba(34, 50, 84, 0));
  border-radius: 50%;
  user-select: none;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px;
  cursor: pointer;
`

const HockeyPuckLogo = styled.img`
  margin-top: 10px;
  width: 70%;
  box-sizing: border-box;
  pointer-events: none;
`

const StyledDropZone = styled.div<{ hovered?: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  width: 352px;
  height: 236px;
  bottom: 31%;
  right: 357px;
  border-radius: 50px 50px 0 0;
  background: ${({ hovered }) =>
    !!hovered ? 'radial-gradient(#fff, #fffa, #fff1)' : 'radial-gradient(#fff 15%, transparent 80%)'};
  box-shadow: ${({ hovered }) => (!!hovered ? '0px 1px 20px 0px #fffc' : 'none')};
`

const IgnoreMouseEvents = styled.div`
  pointer-events: none;
  cursor: default;
`

const DropZoneLabel = styled.p`
  font-size: 24px;
  color: #0007;
  width: 200px;
  text-align: center;
`
