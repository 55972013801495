import { BooleanResult } from 'components/Question/QuestionMetrics/BooleanResult'
import { MultipleChoiceResult } from 'components/Question/QuestionMetrics/MultipleChoiceResult'
import { RankResult } from 'components/Question/QuestionMetrics/RankResult'
import { RateResult } from 'components/Question/QuestionMetrics/RateResult'
import { TextResult } from 'components/Question/QuestionMetrics/TextResult'
import { BooleanQuestion } from 'components/Question/QuestionPreview/BooleanQuestion'
import { CardChoiceQuestion } from 'components/Question/QuestionPreview/CardChoiceQuestion'
import { ImageChoiceQuestion } from 'components/Question/QuestionPreview/ImageChoiceQuestion'
import { ImageRankQuestion } from 'components/Question/QuestionPreview/ImageRankQuestion'
import { MultipleChoiceQuestion } from 'components/Question/QuestionPreview/MultipleChoiceQuestion'
import { RankQuestion } from 'components/Question/QuestionPreview/RankQuestion'
import { RateQuestion } from 'components/Question/QuestionPreview/RateQuestion'
import { TextQuestion } from 'components/Question/QuestionPreview/TextQuestion'
import styled from 'styled-components/macro'

interface Props {
  question: Question
  orientation?: 'desktop' | 'mobile'
  stats?: boolean
}

export const Question = ({ question, orientation = 'mobile', stats = true }: Props) => {
  if (question.votes?.total > 0 && stats) {
    switch (question.type) {
      case 'boolean':
        return <BooleanResult question={question} />
      case 'multiple-choice':
        return <MultipleChoiceResult question={question} />
      case 'image-choice':
        return <MultipleChoiceResult question={question} />
      case 'rank':
        return <RankResult question={question} />
      case 'image-rank':
        return <RankResult question={question} />
      case 'rate':
        return <RateResult question={question} />
      case 'text':
        return <TextResult question={question} />
      default:
        return <NoOp />
    }
  } else {
    switch (question.type) {
      case 'boolean':
        return <BooleanQuestion question={question} orientation={orientation} />
      case 'multiple-choice':
        return <MultipleChoiceQuestion question={question} />
      case 'image-choice':
        return <ImageChoiceQuestion question={question} orientation={orientation} />
      case 'rank':
        return <RankQuestion question={question} orientation={orientation} />
      case 'image-rank':
        return <ImageRankQuestion question={question} orientation={orientation} />
      case 'rate':
        return <RateQuestion question={question} orientation={orientation} />
      case 'text':
        return <TextQuestion question={question} orientation={orientation} />
      case 'card-multiple-choice':
        return <CardChoiceQuestion question={question} orientation={orientation} />
      default:
        return <NoOp />
    }
  }
}

const NoOp = styled.div``
