import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useGetS3SignedUrlMutation } from './miscApi'

export interface PhotoEditorState {
  status: 'idle' | 'loading'
  open: boolean
  question?: string
  image?: File
}

const initialState: PhotoEditorState = {
  status: 'idle',
  open: false,
}

export const photoEditorSlice = createSlice({
  name: 'photoEditor',
  initialState,
  reducers: {
    photoEditorOpened: (state: PhotoEditorState, action: PayloadAction<string>) => {
      state.question = action.payload
      state.open = true
    },
    photoEditorClosed: (state: PhotoEditorState) => {
      state.question = undefined
      state.open = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncUploadImage.pending, (state) => {
        console.log('Starting')
        state.status = 'loading'
      })
      .addCase(asyncUploadImage.fulfilled, (state, action) => {
        console.log('Finished')
        console.log(action.payload)
        state.status = 'idle'
      })
  },
})

export const asyncUploadImage = createAsyncThunk<string, { blob: Blob }>(
  'photoEditor/imageUploaded',
  async (blob) => {
    console.log('Hey there')
    const [getSignedUrl] = useGetS3SignedUrlMutation()
    console.log(getSignedUrl)
    const url = await getSignedUrl().unwrap()
    console.log(url)
    return 'fuck'
  }
)

// export const asyncUploadImage = createAsyncThunk<
//   { url: string },
//   { s3SignedUrl: { signedRequest: string; resourceUrl: string }; blob: Blob }
// >('photoEditor/imageUploaded', async (s3SignedUrl, blob) => {

// })

export const { photoEditorOpened, photoEditorClosed } = photoEditorSlice.actions
