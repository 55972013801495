import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { usePostSurveyMutation } from 'services/surveyApi'

import { useAutosave } from 'utils/useAutosave'

import {
  Add,
  AddRounded,
  AppsRounded,
  Check,
  Close,
  FormatListBulletedRounded,
  Group,
  GroupAdd,
  Person,
} from '@mui/icons-material'
import { Button, Fab, IconButton } from '@mui/material'

import { ContextMenu, ContextMenuItem } from 'components/ContextMenu'
import { Topbar } from 'components/Elements/Topbar'
import { SearchIcon } from 'components/Icons/icons'
import { Popup } from 'components/Popup'
import { AccountMenu } from 'components/UI/AccountMenu'
import { SurveyPreview } from 'pages/Engage/SurveyPreview'
import {
  useDeleteWorkspaceMutation,
  useGetWorkspacesQuery,
  usePostWorkspaceMutation,
  usePutWorkspaceMutation,
} from 'services/workspaceApi'
import { ExperiencesGridItem } from './ExperiencesGridItem'
import { Upgrade } from './Upgrade'

const IS_PAID_PLAN = false

export const Workspaces = () => {
  const navigate = useNavigate()

  const [sidebarExpanded, setSidebarExpanded] = useState(false)
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid')

  const [searchOpen, setSearchOpen] = useState(false)
  const [upgradeOpen, setUpgradeOpen] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [workspaceSearchResults, setWorkspaceSearchResults] = useState<any[]>([])
  const [moduleSearchableUnitResults, setModuleSearchableUnitResults] = useState<any[]>([])

  const [postWorkspace] = usePostWorkspaceMutation()
  const [putWorkspace] = usePutWorkspaceMutation()
  const [deleteWorkspace] = useDeleteWorkspaceMutation()

  const { data: workspaces, isLoading: workspacesLoading } = useGetWorkspacesQuery()

  const [postExperience] = usePostSurveyMutation()

  const [statusFilter, setStatusFilter] = useState('all')

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>()

  const [dragHoveredWorkspace, setDragHoveredWorkspace] = useState<Workspace | undefined>()

  const [draggedExperience, setDraggedExperience] = useState<Survey | undefined>()

  useEffect(() => {
    if (selectedWorkspace?._id) {
      setSelectedWorkspace(workspaces?.filter((ws) => ws._id === selectedWorkspace._id)[0])
    }
  }, [workspaces])

  const runSearch = () => {
    // run workspace and module-specific searches in parallel
    setWorkspaceSearchResults([])
    setModuleSearchableUnitResults([])
  }

  useAutosave(searchInput, runSearch, 333)

  const createWorkspace = (workspace: any) => {
    postWorkspace(workspace)
      .unwrap()
      .then((createdWorkspace) => setSelectedWorkspace(createdWorkspace))
    // setWorkspaces([workspace, ...workspaces])
    setSelectedWorkspace(workspace)
  }

  const createExperience = async () => {
    if (!selectedWorkspace || !selectedWorkspace._id) {
      alert('To create a new experience, start by selecting a workspace for it to live in')
      return
    }
    const name = window.prompt('What should your experience to be called?', 'New Untitled Experience')
    if (name) {
      postExperience({ name })
        .unwrap()
        .then((createdSurvey) => {
          console.log('createdSurvey:')
          console.log(createdSurvey)
          putWorkspace({
            _id: selectedWorkspace._id,
            delta: { items: [createdSurvey, ...(selectedWorkspace.items || [])] },
          })
        })
    }
  }

  const handleMoveExperience = (destinationWorkspace: Workspace) => {
    if (
      !draggedExperience?._id ||
      !selectedWorkspace?._id ||
      destinationWorkspace._id === selectedWorkspace?._id
    ) {
      return
    }
    if (window.confirm('Move to workspace ' + destinationWorkspace.name + '?')) {
      putWorkspace({
        _id: destinationWorkspace._id,
        delta: { items: [draggedExperience, ...(destinationWorkspace.items || [])] },
      })
      putWorkspace({
        _id: selectedWorkspace._id,
        delta: {
          items: [...selectedWorkspace.items].filter((experience) => experience._id !== draggedExperience._id),
        },
      })
    }
  }

  const toggleSearchOpen = () => setSearchOpen(!searchOpen)

  const totalSearchResultCount = workspaceSearchResults.length + moduleSearchableUnitResults.length

  const privateWorkspaces: Workspace[] = workspaces?.filter((ws) => ws.visibility === 'private') || []

  const sharedWorkspaces: Workspace[] = workspaces?.filter((ws) => ws.visibility === 'private') || []

  const WorkspaceList = ({
    visibility,
    workspaces,
    onCreateWorkspace,
  }: {
    visibility: string
    workspaces: any[]
    onCreateWorkspace?: any
  }) => {
    const [creatorOpen, setCreatorOpen] = useState(false)
    const [creatorNameInput, setCreatorNameInput] = useState('')

    const toggleCreatorOpen = () => setCreatorOpen(!creatorOpen)

    return (
      <StyledWorkspaceList>
        <WorkspaceListHeader>
          <WorkspaceListTitle>
            <span style={{ verticalAlign: 'middle', marginRight: 5, marginLeft: 15 }}>
              {visibility === 'private' ? <Person sx={{ width: 20 }} /> : <Group sx={{ width: 20 }} />}
            </span>
            <span style={{ verticalAlign: 'middle' }}>
              <span style={{ textTransform: 'capitalize' }}>{visibility}</span> workspaces
            </span>
          </WorkspaceListTitle>

          {visibility === 'private' && (
            <IconButton sx={{ marginRight: '5px' }} onClick={toggleCreatorOpen}>
              <Add />
            </IconButton>
          )}
        </WorkspaceListHeader>

        <WorkspaceListItems>
          {workspaces.map((workspace, index) => (
            <ContextMenu
              key={`${visibility}-li-${index}`}
              items={
                <>
                  <ContextMenuItem type="dangerous" action={() => deleteWorkspace(workspace._id)}>
                    Delete
                  </ContextMenuItem>
                </>
              }
            >
              <WorkspaceListItem
                selected={selectedWorkspace?._id === workspace._id}
                hovered={dragHoveredWorkspace?._id === workspace._id}
                onClick={() => setSelectedWorkspace(workspace)}
                onDrop={() => {
                  handleMoveExperience(workspace)
                  setDragHoveredWorkspace(undefined)
                }}
                onDragEnter={() => {
                  setDragHoveredWorkspace(workspace)
                }}
                onDragLeave={() => {
                  setDragHoveredWorkspace(undefined) // when dragging between two, dragLeave will always fire before dragEnter
                }}
                onDragOver={(event) => event.preventDefault()}
              >
                <WorkspaceListItemLabel>{workspace.name}</WorkspaceListItemLabel>
                <WorkspaceListItemLabel style={{ color: '#777' }}>
                  {workspace.items?.length || 0}
                </WorkspaceListItemLabel>
              </WorkspaceListItem>
            </ContextMenu>
          ))}
        </WorkspaceListItems>

        {creatorOpen && (
          <Popup
            handleBackgroundClick={toggleCreatorOpen}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <CreatorOverlay>
              <Close
                sx={{ position: 'absolute', top: 10, right: 10, color: '#3333', cursor: 'pointer' }}
                onClick={toggleCreatorOpen}
              />
              <CreatorTitle>New {visibility} workspace</CreatorTitle>
              <CreatorInput
                autoFocus={true}
                placeholder="Workspace name..."
                onChange={(e) => setCreatorNameInput(e.target.value)}
              />
              <Fab
                color="success"
                sx={{
                  position: 'absolute',
                  right: 10,
                  bottom: -18,
                  width: 30,
                  minWidth: 30,
                  maxWidth: 30,
                  height: 30,
                  minHeight: 30,
                  maxHeight: 30,
                  padding: 3,
                  margin: '0px 10px 0px 20px',
                  filter: 'brightness(1.3)',
                }}
                onClick={
                  !onCreateWorkspace ? undefined : () => onCreateWorkspace({ name: creatorNameInput, visibility })
                }
              >
                <Check />
              </Fab>
            </CreatorOverlay>
          </Popup>
        )}
      </StyledWorkspaceList>
    )
  }

  return (
    <StyledWorkspaces>
      <Topbar right={<AccountMenu />} />
      <WorkspacesSidebar expanded={sidebarExpanded}>
        <SearchWrapper onClick={toggleSearchOpen}>
          <SearchIcon width="1em" height="1em" style={{ color: '#aaaaaa', padding: '0.5em 0.5em 0.5em 0.5em' }} />
          <SearchLabel>Jump to a workspace or experience</SearchLabel>
        </SearchWrapper>
        <PrivateWorkspaces>
          <WorkspaceList visibility="private" workspaces={privateWorkspaces} onCreateWorkspace={createWorkspace} />
        </PrivateWorkspaces>

        {IS_PAID_PLAN && (
          <SharedWorkspaces>
            <WorkspaceList visibility="shared" workspaces={sharedWorkspaces} onCreateWorkspace={createWorkspace} />
          </SharedWorkspaces>
        )}

        {/* <div style={{ position: 'absolute', bottom: 60, left: 10 }}>
          <WorkspaceHeader>
            Experience completion + <br />
            seat consumption bar
          </WorkspaceHeader>
          <WorkspaceHeader>Apps & Integrations</WorkspaceHeader>
          <WorkspaceHeader>Brand kit?</WorkspaceHeader>
        </div> */}
      </WorkspacesSidebar>

      <Workspace>
        {!selectedWorkspace ? (
          <WorkspaceHeader style={{ marginTop: 40, color: '#777a', textAlign: 'center' }}>
            No workspace selected
          </WorkspaceHeader>
        ) : (
          <div>
            <Overview>
              <WorkspaceHeader>{selectedWorkspace.name}</WorkspaceHeader>

              <Button
                onClick={() => setUpgradeOpen(true)}
                size="small"
                sx={{ fontSize: '12px', color: '#444a' }}
                startIcon={<GroupAdd />}
                variant="text"
              >
                Invite team members
              </Button>

              <ViewModeToggle>
                <ViewMode
                  selected={viewMode === 'grid'}
                  onClick={viewMode === 'grid' ? undefined : () => setViewMode('grid')}
                >
                  <AppsRounded sx={{ marginRight: '4px' }} /> Grid
                </ViewMode>
                <ViewMode
                  selected={viewMode === 'list'}
                  onClick={viewMode === 'list' ? undefined : () => setViewMode('list')}
                >
                  <FormatListBulletedRounded sx={{ marginRight: '4px' }} /> List
                </ViewMode>
              </ViewModeToggle>
            </Overview>

            {!!selectedWorkspace && (
              <>
                {viewMode === 'grid' && (
                  <ExperienceGrid>
                    {selectedWorkspace?.items?.map((survey, index) => (
                      <ExperiencesGridItem
                        key={'egi' + index}
                        experience={survey}
                        onDragStart={() => setDraggedExperience(survey)}
                      />
                    ))}

                    <AddCard onClick={createExperience}>
                      <AddRounded sx={{ fontSize: 'inherit' }} />
                      <p style={{ marginTop: 10, fontWeight: 500 }}>New experience</p>
                    </AddCard>
                  </ExperienceGrid>
                )}

                {viewMode === 'list' && (
                  <ExperienceList style={{ marginBottom: 300 }}>
                    {selectedWorkspace?.items?.map((survey, index) => (
                      <div style={{ zoom: 0.8 }}>
                        <SurveyPreview key={index} survey={survey} />
                      </div>
                    ))}
                  </ExperienceList>
                )}
              </>
            )}
          </div>
        )}
      </Workspace>

      {upgradeOpen && <Upgrade handleClose={() => setUpgradeOpen(false)} />}

      {searchOpen && (
        <Popup
          handleBackgroundClick={toggleSearchOpen}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SearchOverlay>
            <SearchBar>
              <SearchIcon
                width="1em"
                height="1em"
                style={{ color: '#aaaaaa', padding: '0.5em 0.5em 0.5em 0.5em' }}
              />
              <SearchInput
                placeholder={`Name of workspace or experience...`}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </SearchBar>
            {totalSearchResultCount > 0 && (
              <>
                {workspaceSearchResults.length > 0 && (
                  <SearchResults>
                    {workspaceSearchResults.map((workspaceSearchResult, index) => (
                      <SearchResult key={'ws-' + index}>
                        <SearchResultLabel>{workspaceSearchResult}</SearchResultLabel>
                      </SearchResult>
                    ))}
                  </SearchResults>
                )}
                {moduleSearchableUnitResults.length > 0 && (
                  <SearchResults>
                    {moduleSearchableUnitResults.map((moduleSearchableUnitResult, index) => (
                      <SearchResult key={'ms-' + index}>
                        <SearchResultLabel>{moduleSearchableUnitResult}</SearchResultLabel>
                      </SearchResult>
                    ))}
                  </SearchResults>
                )}
              </>
            )}
          </SearchOverlay>
        </Popup>
      )}
    </StyledWorkspaces>
  )
}

const StyledWorkspaceList = styled.div``

const WorkspaceListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const WorkspaceListItems = styled.div``

const WorkspaceListTitle = styled.div`
  font-size: 16px;
  color: #555;
`

const WorkspaceListItem = styled.div<{ hovered?: boolean; selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
  transition: 0.2s all ease;

  ${({ hovered, selected }) =>
    hovered && !selected
      ? `
    padding: 10px 30px 10px 10px;
    background-color: #5faadc40;
  `
      : `
    padding: 10px 20px;
    background-color: transparent;
  `}

  ${({ selected }) => (selected ? 'background-color: #5faadc60 !important;' : '')}


  &:hover {
    background-color: #5faadc40;
  }
`

const WorkspaceListItemLabel = styled.p``

const CreatorOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  width: 400px;
  height: max-content;
  padding: 20px;
  border-radius: 6px;
  background-color: white;
  overflow: visible;
`

const CreatorTitle = styled.p`
  margin: 10px;
  font-size: 18px;
  padding-bottom: 20px;
  border-bottom: 1px solid #7773;
  user-select: none;
`

const CreatorInput = styled.input`
  margin: 10px;
  padding: 6px 5px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 20px;
`

const StyledWorkspaces = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  /* position: fixed; */
  /* top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  /* background: linear-gradient(45deg, #2dc2c8, #30176b); */
`

const WorkspacesSidebar = styled.div<{ expanded?: boolean }>`
  position: relative;
  top: 0;
  left: 0;
  min-width: ${({ expanded }) => (!expanded ? 250 : 500)}px;
  height: 100vh;
  background: white;
  box-shadow: rgb(0 0 0 / 7%) -1px 0px inset;
`

const WorkspaceHeader = styled.div`
  font-size: 18px;
  color: #215d75;
  font-weight: 700;
  margin-right: 40px;
  padding: 3px 0;
`

const Overview = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #aaa3;
`

const ExperienceGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
`

const AddCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 180px;
  height: 220px;
  margin: 0 20px 20px 0;
  justify-content: center;
  box-shadow: none;
  font-size: 80px;
  cursor: pointer;
  background: transparent;
  color: #215d75;
  border: 2px dashed #5555;
  border-radius: 8px;
  box-sizing: border-box;
`

const ExperienceList = styled.div`
  max-width: 1200px;
`

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px;
  border-radius: 4px;
  background-color: #e3e3e3;
  transition: 100ms background-color ease;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`

const SearchLabel = styled.p`
  color: #333;
  font-size: 12px;
  padding-right: 10px;
`

const SearchOverlay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  width: 500px;
  height: max-content;
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
`

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
`

const SearchInput = styled.input`
  flex: 1;
  width: max-content;
  padding-right: 10px;
  outline: none !important;
  border: none !important;
  font-size: 18px;
`

const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: max-content;
  box-shadow: rgb(0 0 0 / 7%) 0px 1px inset;
`

const SearchResult = styled.div`
  padding: 20px;
  cursor: pointer;

  &:hover {
    background-color: #5faadc40;
  }
`

const SearchResultLabel = styled.p`
  text-align: left;
`

const PrivateWorkspaces = styled.div`
  margin: 20px 0;
`

const SharedWorkspaces = styled.div`
  margin: 20px 0;
`

const Workspace = styled.div`
  flex: 1;
  height: calc(100vh - 50px);
  padding: 20px 30px;
  box-sizing: border-box;
  overflow: auto;
  background: #fafafa;
`

const ViewModeToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #215d75;
`

const ViewMode = styled.p<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 5px 10px;

  ${({ selected }) =>
    selected
      ? `
    color: white;
    background-color: #215d75;
    cursor: default;
  `
      : `
  color: #215d75;
    background-color: transparent;
    cursor: pointer;
  `}
`
