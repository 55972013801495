import { AddRounded, SportsScore } from '@mui/icons-material'
import { Loading, MaxWidthAndCenter } from 'components/SharedPageComponents'
import styled from 'styled-components/macro'

export const ExperienceIntegrations = ({ experience }: { experience: any }) => {
  if (!experience) {
    return <Loading>Loading...</Loading>
  }

  return (
    <MaxWidthAndCenter style={{ width: 1000 }}>
      <Main>
        <SectionTitle>Integrations</SectionTitle>
        <Section>
          <Card>
            <img style={{ height: 40 }} src="/img/zapier.svg" />
            <CardTitle>Zapier</CardTitle>
            <CardText>Connect your Experience to thousands of apps and services, using our custom Zaps</CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <Card>
            <img style={{ height: 30, padding: '5px 0' }} src="/img/ganalytics.svg" />
            <CardTitle>Google Analytics</CardTitle>
            <CardText>Collect detailed engagement metrics to gain insights into trends</CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <Card>
            <img style={{ height: 40 }} src="/img/gsheets.svg" />
            <CardTitle>Google Sheets</CardTitle>
            <CardText>Export your Experience completion data to a Google Sheet</CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <Card>
            <img style={{ height: 40 }} src="/img/excel.svg" />
            <CardTitle>Excel Online</CardTitle>
            <CardText>Export your Experience completion data to an Excel Sheet</CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <Card>
            <img style={{ height: 40 }} src="/img/mailchimp.svg" />
            <CardTitle>Mailchimp</CardTitle>
            <CardText>
              Send new contacts to your Mailchimp lists, and tag them so they're easy to organize
            </CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <Card>
            <img style={{ height: 40 }} src="/img/hubspot.svg" />
            <CardTitle>HubSpot</CardTitle>
            <CardText>
              Send Experience data to HubSpot to quickly follow up on new leads and built out profiles
            </CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <Card>
            <img style={{ height: 40 }} src="/img/airtable.svg" />
            <CardTitle>Airtable</CardTitle>
            <CardText>Send Experience completions to Airtable to work with the data</CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <Card>
            <img style={{ height: 40 }} src="/img/slack.svg" />
            <CardTitle>Slack</CardTitle>
            <CardText>
              Notify a channel or individual in Slack with Experience data to take instant action
            </CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <Card>
            <img style={{ height: 40 }} src="/img/pipedrive.svg" />
            <CardTitle>Pipedrive</CardTitle>
            <CardText>
              Turn new contacts into deals and add them to any stage of your sales pipeline in Pipedrive
            </CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <Card>
            <img style={{ height: 40 }} src="/img/automateio.png" />
            <CardTitle>Automate.io</CardTitle>
            <CardText>
              Instantly send Experience completions to 100+ popular apps and trigger workflows
            </CardText>
            <IntegrateButton>Connect</IntegrateButton>
          </Card>

          <AddCard>
            <AddRounded sx={{ fontSize: 'inherit' }} />
          </AddCard>
        </Section>

        <SectionTitle style={{ marginTop: 70 }}>Webhooks</SectionTitle>
        <Section>
          <Card>
            <SportsScore />
            <CardTitle>Experience Completions</CardTitle>
            <CardText>Receive a postback whenevery someone completes your Experience</CardText>
            <IntegrateButton>Configure</IntegrateButton>
          </Card>

          <AddCard>
            <AddRounded sx={{ fontSize: 'inherit' }} />
          </AddCard>
        </Section>
      </Main>
    </MaxWidthAndCenter>
  )
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  padding: 40px 40px 100px;
`

const SectionTitle = styled.p`
  font-size: 20px;
  margin: 30px 0;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const CardTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #333;
  margin: 10px 0;
  margin-top: 20px;
`

const CardText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
`

const IntegrateButton = styled.p`
  margin-top: auto;
  padding: 10px 20px;
  background-color: #008080;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  transition: 200ms background-color ease;

  &:hover {
    background-color: #007070;
  }
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 210px;
  max-width: 210px;
  height: 180px;
  padding: 30px 20px;
  border-radius: 2px;
  background: white;
  border: 2px solid white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  margin: 0 20px 20px 0;
`

const AddCard = styled(Card)`
  justify-content: center;
  box-shadow: none;
  font-size: 80px;
  cursor: pointer;
  background: transparent;
  color: #458c8b;
  border: 2px dashed #5555;
  border-radius: 2px;
  box-sizing: content-box;
`
