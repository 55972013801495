import React from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components/macro'
import { underlinedNavItem } from './navStyles'

interface Props {
  left?: React.ReactNode
  children?: React.ReactNode
  right?: React.ReactNode
}

export const Topbar = ({ left, children, right }: Props) => {
  const { pathname } = useLocation()

  const isConversations = pathname.indexOf('/conversations/') !== -1 // todo: convert to useAppSelector

  return (
    <>
      <StyledTopbar>
        <Left>
          <Logo src={'/img/branding/stars_dark.png'} />
          <Brand
            to="/dashboard"
            style={!isConversations ? {} : { backgroundImage: 'linear-gradient(45deg,#372bb9,#9a34a7)' }}
          >
            Storyforms
          </Brand>
          {left}
        </Left>
        <Center>{children}</Center>
        <Right>{right}</Right>
      </StyledTopbar>
      <Outlet />
    </>
  )
}

export const NavItem = ({ path, children }: { path: string, children: React.ReactNode }) => {
  const { pathname } = useLocation()
  const selected = (pathname.endsWith(path) && path.length > 0) ? !(path.length === 0) : path.length === 0

  return (
    <StyledNavItem to={path} selected={selected}>{children}</StyledNavItem>
  )
}

const StyledNavItem = styled(NavLink) <{ selected: boolean }>`
  ${underlinedNavItem}
`

const StyledTopbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  border-bottom: 1px solid #413b57;
  z-index: 997;
  background: #ffffff;
  padding: 0px 20px 0px 0px;
`

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 25%;
  height: 100%;
  background: linear-gradient(90deg, rgba(50, 33, 114, 1) 0%, rgba(46, 128, 165, 1) 70%, rgba(46, 128, 165, 0));


`

const Logo = styled.img`
  margin: 0 7px 0 20px;
  height: 60%;
`

const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 50px;
  height: 100%;
`

const Brand = styled(NavLink)`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  user-select: none;
  font-family: 'Montserrat';
  text-decoration: none;
  

  background: #ffffff;
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 50%;
`

const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 25%;
`
