import { ChevronLeft } from '@mui/icons-material'
import { ContextMenu, ContextMenuItem } from 'components/ContextMenu'
import { Carousel, CarouselClip, CarouselPage } from 'components/Elements/Carousel'
import { Sidebar, SidebarDivision, SidebarElementX } from 'components/Elements/Sidebar'
import { useState } from 'react'
import { useDeleteQuestionMutation, usePostQuestionMutation } from 'services/questionApi'
import { usePutSurveyMutation } from 'services/surveyApi'
import styled from 'styled-components/macro'
import { centered } from 'styles/layout'

const QUESTION_TYPES = [
  {
    label: 'Standard',
    type: 'section',
  },
  {
    label: '',
    type: 'boolean',
    icon: '/img/standard_icons/boolean.png',
  },
  {
    label: '',
    type: 'multiple-choice',
    icon: '/img/standard_icons/multiple-choice.png',
  },
  {
    label: '',
    type: 'image-choice',
    icon: '/img/standard_icons/image-choice.png',
  },
  {
    label: '',
    type: 'rank',
    icon: '/img/standard_icons/rank.png',
  },
  {
    label: '',
    type: 'image-rank',
    icon: '/img/standard_icons/image-rank.png',
  },
  {
    label: '',
    type: 'rate',
    icon: '/img/standard_icons/rate.png',
  },
  {
    label: '',
    type: 'text',
    icon: '/img/standard_icons/text.png',
  },
  {
    label: 'Immersive',
    type: 'section',
  },
  // {
  //   label: '',
  //   type: 'card-boolean',
  //   icon: '/img/standard_icons/card-boolean.png',
  // },
  {
    label: 'Card Multiple Choice',
    type: 'card-multiple-choice',
    video: 'https://i.gyazo.com/f0a7f1186bf974e407256823094990a8.mp4',
  },
  // {
  //   label: '',
  //   type: 'card-ranking',
  //   icon: '/img/standard_icons/card-ranking.png',
  // },
]

export const LeftBar = ({
  experience,

  open,
  onToggleOpen,

  selectedQuestion,
  onSelectQuestion,

  setStagingSurvey, // todo: revisit this because the pass-down for direct call is a different paradigm than the pass-down of callback handlers so the business logic can stay upstairs
}: {
  experience: any

  open: boolean
  onToggleOpen: any

  selectedQuestion: any
  onSelectQuestion: any

  setStagingSurvey: any
}) => {
  const survey = experience as Partial<Survey>

  const [putSurvey] = usePutSurveyMutation()

  const [postQuestion, { isLoading: questionPostIsLoading, data: postedQuestion }] = usePostQuestionMutation()
  const [deleteQuestion] = useDeleteQuestionMutation()

  const fullyDeleteQuestion = (questionId: string) => {
    putSurvey({
      _id: survey._id!,
      delta: {
        questions: survey.questions?.filter((currentQuestion) => currentQuestion._id !== questionId),
      },
    })
    deleteQuestion({ _id: questionId })
  }

  const duplicateQuestion = async (questionId: string, duplicatedAt: number) => {
    const question = { ...survey!.questions!.filter((question) => question._id === questionId)[0], _id: undefined }
    console.log(question)
    const result = await postQuestion({ ...question })
    if ('data' in result) {
      const questions = [...survey.questions!]
      questions.splice(duplicatedAt, 0, result.data)
      console.log(questions)
      putSurvey({ _id: survey._id!, delta: { questions } })
    }
  }

  const [questionTypeSelectorOpen, setQuestionTypeSelectorOpen] = useState(false)

  const [draggingIndex, setDraggingIndex] = useState<number | null>(null)
  const handleDragStart = (index: number) => setDraggingIndex(index)

  const enableDropping = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault()

  const handleDrop = (index: number) => {
    const questions = [...survey.questions!]
    const draggedValue = questions.splice(draggingIndex!, 1)[0]
    questions.splice(index, 0, draggedValue)
    putSurvey({ _id: survey._id!, delta: { questions } })
    setStagingSurvey((currentSurvey: any) => ({ ...currentSurvey, questions }))
  }

  return (
    <Sidebar position="left" expanded={!!open} onToggleOpen={onToggleOpen}>
      <CarouselClip>
        <Carousel fieldNumber={questionTypeSelectorOpen ? 1 : 0}>
          <CarouselPage>
            <SidebarDivision>
              <SidebarElementX>
                <span style={{ fontWeight: 600 }}>Elements</span>
                <AddExperience onClick={() => setQuestionTypeSelectorOpen(true)}>+</AddExperience>
                {/* {elementsOpen && <ExperienceSelector closeModal={() => setElementsOpen(false)} />} */}
              </SidebarElementX>
            </SidebarDivision>
            <SidebarScroller>
              {survey?.questions?.map((question, index) => (
                <ContextMenu
                  key={index}
                  items={
                    <>
                      <ContextMenuItem
                        action={() => {
                          duplicateQuestion(question._id!, index)
                        }}
                      >
                        Duplicate
                      </ContextMenuItem>
                      <ContextMenuItem
                        type="dangerous"
                        action={() => {
                          if (window.confirm('Are you sure you want to delete this question?')) {
                            fullyDeleteQuestion(question._id!)
                            onSelectQuestion(null)
                          }
                        }}
                      >
                        Delete
                      </ContextMenuItem>
                    </>
                  }
                >
                  <SidebarItem
                    draggable="true"
                    onDragStart={(e) => handleDragStart(index)}
                    onDragOver={enableDropping}
                    onDrop={(e) => handleDrop(index)}
                    onClick={() => {
                      setQuestionTypeSelectorOpen(false)
                      onSelectQuestion(question)
                    }}
                    selected={question._id === selectedQuestion?._id}
                  >
                    <QuestionCounter onClick={() => onSelectQuestion(question)}>{index + 1}</QuestionCounter>
                    {question.prompt || 'Untitled question'}
                  </SidebarItem>
                </ContextMenu>
              ))}
            </SidebarScroller>
          </CarouselPage>
          <CarouselPage>
            <div>
              <p style={{ fontSize: 16, padding: '10px 5px' }}>
                <ChevronLeft
                  sx={{ verticalAlign: 'middle', fontSize: '30px', cursor: 'pointer' }}
                  onClick={() => setQuestionTypeSelectorOpen(false)}
                />
                <span style={{ verticalAlign: 'middle', fontWeight: 600, marginLeft: 4 }}>Add a question</span>
              </p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'flex-start',
                  flexWrap: 'wrap',
                  padding: 20,
                }}
              >
                {QUESTION_TYPES.map((questionType, index) =>
                  questionType.type === 'section' ? (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        marginTop: 20,
                        marginBottom: 10,
                      }}
                    >
                      <div style={{ flex: 1, height: 1, backgroundColor: '#333' }}></div>
                      <p style={{ margin: '0 8px' }}>{questionType.label}</p>
                      <div style={{ flex: 1, height: 1, backgroundColor: '#333' }}></div>
                    </div>
                  ) : (
                    <QuestionType
                      key={index}
                      onClick={() =>
                        postQuestion({ type: questionType.type, prompt: '', choices: [] })
                          .unwrap()
                          .then((question: Question) => {
                            setQuestionTypeSelectorOpen(false)
                            putSurvey({
                              _id: survey._id!,
                              delta: { questions: [...(survey.questions || []), question] },
                            })
                          })
                      }
                    >
                      {questionType.video ? (
                        <video
                          style={{ width: 100, cursor: 'pointer' }}
                          src={questionType.video}
                          typeof="video/mp4"
                          autoPlay={true}
                          muted={true}
                          loop={true}
                          controls={false}
                        />
                      ) : questionType.icon ? (
                        <img style={{ width: 80, cursor: 'pointer' }} src={questionType.icon} />
                      ) : null}
                      {questionType.label && <p>{questionType.label}</p>}
                    </QuestionType>
                  )
                )}
              </div>
            </div>
          </CarouselPage>
        </Carousel>
      </CarouselClip>
    </Sidebar>
  )
}

const SidebarScroller = styled.div`
  overflow: auto;
  height: calc(100vh - 100px);
`

const SidebarItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 20px;
  font-size: 12px;
  display: flex;
  cursor: pointer;
  transition: all 0.2s;
  border-bottom: 1px solid #eeeeee;
  background: ${({ selected }) => (selected ? '#eeeeee' : 'transparent')};

  &:hover {
    background: ${({ selected }) => (selected ? '#eeeeee' : '#f8f8f8')};
  }
`

const AddExperience = styled.span`
  ${centered}
  height: 20px;
  width: 20px;
  background: #dedede;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.1s;

  &:hover {
    background: #cccccc;
  }
`

const QuestionCounter = styled.div`
  ${centered}
  border-radius: 6px;
  min-width: 20px;
  padding: 2px 3px;
  height: 100%;
  /* background: linear-gradient(45deg, rgb(17, 210, 219), rgb(13, 131, 217)); */
  background-color: #5db0cf;
  color: #ffffff;
  margin-right: 10px;
`

const QuestionType = styled.div`
  margin: 10px;
  text-align: center;
  opacity: 0.5;
  transition: opacity 300ms ease;

  &:hover {
    opacity: 1;
  }
`
