import { useEffect, useRef } from 'react'

// observable: what to observe for changes. supports single value + array
// action: function to run when autosave is triggered
// autosaveTimeout: how long to wait to autosave after a user has paused input
export const useAutosave = (observable: any | any[], action: () => void, autosaveTimeout: number = 1000) => {
  const autosaveTaskId = useRef<any>()
  useEffect(
    () => {
      if (autosaveTaskId.current) {
        clearTimeout(autosaveTaskId.current)
      }
      autosaveTaskId.current = setTimeout(action, autosaveTimeout)
    },
    Array.isArray(observable) ? [...observable] : [observable]
  )
}
