import { useAppDispatch } from 'app/hooks'
import { useEffect, useRef, useState } from 'react'
import { asyncForgotPassword, forgotPasswordEmailSent, forgotPasswordFlowEnded } from 'services/authSlice'
import { BottomActionText, Input, LoginBox, Name, Seperation, Subheader, Submit } from './LoginComponents'

export const ForgotPassword = ({ style }: { style?: any }) => {
  const dispatch = useAppDispatch()
  const [email, setEmail] = useState<string>('')
  const [emailStatus, setEmailStatus] = useState<string>('default')
  const emailInput = useRef<HTMLInputElement>(null) // @jared, this is the proper way to do refs, if you'd like to do things with them. Please do not overuse them.

  useEffect(() => {
    const newEmailStatus = email.length === 0 ? 'default' : /\S+@\S+\.\S+/.test(email) ? 'valid' : 'entering'
    setEmailStatus(newEmailStatus)
  }, [email])

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (emailStatus !== 'valid') {
      alert('Please enter a valid email address.')
      return emailInput.current?.focus()
    }
    dispatch(asyncForgotPassword({ username: email }))
      .then((result: any) => {
        if (result.error) return alert(`Error: ${result.error.message}`)
        dispatch(forgotPasswordEmailSent())
      })
      .catch((e) => {
        alert(
          `Unable to reset your password at this time. Please contact us if this issue persists.\n\nUnknown error: ${e}`
        )
      })
  }
  return (
    <LoginBox style={style}>
      <Name>BWI</Name>
      <Subheader>Enter the email associated with your account to reset your password:</Subheader>
      <form autoComplete="off" spellCheck="false">
        <Input
          ref={emailInput}
          key="email"
          status={emailStatus}
          placeholder="Email"
          autoComplete="username"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value.trim().toLowerCase())
          }}
        />
        <div>
          <Submit type="submit" value="Continue" onClick={handleSubmit} />
          <Seperation>or</Seperation>
          <BottomActionText onClick={() => dispatch(forgotPasswordFlowEnded())}>
            return to sign in
          </BottomActionText>
        </div>
      </form>
    </LoginBox>
  )
}
