import { useAppDispatch } from 'app/hooks'
import { RootState } from 'app/store'
import { CanvasAction, ExportFormat, ImageFormat, PhotoEditorSDKUI, Tool, UIEvent } from 'photoeditorsdk'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGetS3SignedUrlMutation } from 'services/miscApi'
import { asyncUploadImage, photoEditorClosed } from 'services/photoEditorSlice'
import { usePutQuestionMutation } from 'services/questionApi'
import styled from 'styled-components/macro'

export const PhotoEditor = ({ image }: { image: string }) => {
  const dispatch = useAppDispatch()
  const [getSignedUrl] = useGetS3SignedUrlMutation()

  const { question } = useSelector((state: RootState) => state.photoEditor)
  const [putQuestion] = usePutQuestionMutation()

  const initEditor = async () => {
    const editor = await PhotoEditorSDKUI.init({
      container: '#photo-editor',
      image,
      license: '',
      export: {
        image: {
          enableDownload: false,
          format: ImageFormat.PNG,
          exportType: ExportFormat.BLOB,
        },
      },
      mainCanvasActions: [CanvasAction.UNDO, CanvasAction.REDO, CanvasAction.EXPORT, CanvasAction.CLOSE],
      forceCrop: true,
      transform: {
        flattenCategories: true,
        categories: [
          {
            identifier: 'storyform_transform_tile',
            items: [
              {
                identifier: 'storyform_transform_tile_image',
                name: 'Storyform Tile',
                ratio: 39 / 61,
                dimensions: {
                  x: 390,
                  y: 610,
                },
              },
            ],
          },
        ],
      },
      custom: {
        languages: {
          en: {
            mainCanvasActions: {
              buttonExport: 'Save',
            },
          },
        },
      },
      defaultTool: Tool.LIBRARY,
      library: {
        enableUpload: true,
      },
    })
    editor.on(UIEvent.CLOSE, () => {
      dispatch(photoEditorClosed())
    })
    editor.on(UIEvent.EXPORT, (blob: Blob) => {
      dispatch(asyncUploadImage({ blob }))
      // getSignedUrl().then(async (value) => {
      //   if ('error' in value) {
      //     console.error(value.error)
      //   } else if ('data' in value) {
      //     dispatch(photoEditorClosed())
      //     dispatch(asyncUploadImage())
      //     await fetch(value.data.signedRequest, {
      //       method: 'PUT',
      //       headers: {
      //         'content-type': img.type,
      //       },
      //       body: img,
      //     })
      //     putQuestion({ _id: question, delta: { image: { url: value.data.resourceUrl } } })
      //   }
      // })
    })
  }

  useEffect(() => {
    initEditor()
  }, [])

  return <StyledPhotoEditor id="photo-editor" />
}

const StyledPhotoEditor = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
`
