import { AppsRounded, FormatListBulletedRounded, PictureAsPdfRounded, TextSnippet } from '@mui/icons-material'
import { Loading, MaxWidthAndCenter } from 'components/SharedPageComponents'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { Question } from '../../components/Question/QuestionPreview/Question'
import { ExperienceMetrics } from './ExperienceMetrics'

export const ExperienceResults = ({ experience }: { experience: any }) => {
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid')

  const survey = experience as Partial<Survey>

  const QuestionRow = ({ question, index }: { question: Partial<Question>; index: number }) => {
    if (!question || !survey.questions) {
      return null
    }
    const prevQuestionVotes = index === 0 ? 0 : survey.questions[index - 1]?.votes?.total || 0
    const abandons = index === 0 ? 0 : prevQuestionVotes - (question.votes?.total || 0)
    const dropoffPct =
      index === 0 ? 0 : (100 * (1 - (question.votes?.total || 0) / (prevQuestionVotes || 1))).toFixed(1)
    return (
      <Row>
        <Cell style={{ flex: 1, marginRight: 40 }}>{question?.prompt}</Cell>
        <Cell>{question.votes?.total || 0}</Cell>
        <Cell>{(4 + Math.random() * 10).toFixed(1)}s</Cell>
        <Cell>
          -{abandons} <span style={{ color: '#3337' }}>({dropoffPct}%)</span>{' '}
        </Cell>
      </Row>
    )
  }

  if (!survey) {
    return <Loading>Loading...</Loading>
  }

  return (
    <MaxWidthAndCenter style={{ width: 'unset', maxWidth: 1000 }}>
      <Main>
        <SectionTitle style={{ marginTop: 10 }}>At a glance</SectionTitle>
        <ExperienceMetrics
          experience={experience}
          style={{ width: '100%', boxSizing: 'border-box', justifyContent: 'space-around' }}
        ></ExperienceMetrics>

        <QuestionResultsSectionTitle>
          Question by question
          <ViewModeToggle>
            <ViewMode
              selected={viewMode === 'grid'}
              onClick={viewMode === 'grid' ? undefined : () => setViewMode('grid')}
            >
              <AppsRounded sx={{ marginRight: '4px' }} /> Grid
            </ViewMode>
            <ViewMode
              selected={viewMode === 'list'}
              onClick={viewMode === 'list' ? undefined : () => setViewMode('list')}
            >
              <FormatListBulletedRounded sx={{ marginRight: '4px' }} /> List
            </ViewMode>
          </ViewModeToggle>
        </QuestionResultsSectionTitle>
        <Section>
          {viewMode === 'grid' && (
            <QuestionResultsGrid>
              {survey.questions?.map((question, index) => (
                <QuestionZoomWrapper key={'qg-' + index}>
                  <Question question={question} orientation="mobile" />
                </QuestionZoomWrapper>
              ))}
            </QuestionResultsGrid>
          )}

          {viewMode === 'list' && (
            <QuestionResultsList>
              <Row style={{ borderTop: 'none' }}>
                <ColumnHeader style={{ flex: 1, marginRight: 40 }}>Questions</ColumnHeader>
                <ColumnHeader>Responses</ColumnHeader>
                <ColumnHeader>Avg. time</ColumnHeader>
                <ColumnHeader>Dropoff</ColumnHeader>
              </Row>

              {survey.questions?.map((question, index) => (
                <QuestionRow key={'ql-' + index} question={question} index={index} />
              ))}
            </QuestionResultsList>
          )}
        </Section>

        <SectionTitle style={{ marginTop: 70 }}>Reports & Exports</SectionTitle>
        <Section>
          <ClickableCard>
            <PdfIcon />
            <CardTitle>PDF Report</CardTitle>
            <CardText>
              Export overall and question-by-question results in a PDF tailored by your Brand Kit.
            </CardText>
          </ClickableCard>
          <ClickableCard>
            <CsvIcon />
            <CardTitle>CSV Export</CardTitle>
            <CardText>
              Download results as a CSV file. Choose which columns to include, and filter rows based on your
              parameters.
            </CardText>
          </ClickableCard>
        </Section>
      </Main>
    </MaxWidthAndCenter>
  )
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  padding: 40px 40px 100px;
`

const SectionTitle = styled.div`
  font-size: 20px;
  margin: 30px 0;
  width: 100%;
`

const QuestionResultsSectionTitle = styled(SectionTitle)`
  margin-top: 90px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const ViewModeToggle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: right;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #629a9a;
  font-size: 14px;
`

const ViewMode = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 5px 10px;

  ${({ selected }) =>
    selected
      ? `
    color: white;
    background-color: #629a9a;
    cursor: default;
  `
      : `
  color: #629a9a;
    background-color: transparent;
    cursor: pointer;
  `}
`

const QuestionResultsGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 900px;
`

const QuestionResultsList = styled.div`
  max-width: 900px;
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid #aaa3;
`

const Cell = styled.div`
  /* box-sizing: border-box; */
  margin-right: 20px;
  min-width: 100px;
  font-size: 14px;
`

const ColumnHeader = styled(Cell)`
  font-weight: 500;
`

const QuestionZoomWrapper = styled.div`
  position: relative;
  flex: auto;
  width: 390px;
  max-width: 390px;
  height: 610px;
  margin: 30px;
  zoom: 0.5;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 20%) 0px 2px 12px;
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 150px;
  height: 140px;
  padding: 20px;
  border-radius: 2px;
  background: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  margin: 0 20px 20px 0;
`

const CardTitle = styled.div`
  font-size: 16px;
  color: #333;
  margin: 10px 0;
`

const CardText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #555;
`

const PdfIcon = styled(PictureAsPdfRounded)``

const CsvIcon = styled(TextSnippet)``

const ClickableCard = styled(Card) <{ hoverBackground?: string }>`
  width: 300px;
  transition: 200ms all ease;
  z-index: 1;

  cursor: pointer;

  &:hover {
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px,
      rgb(0 0 0 / 2%) 0px 12px 16px;

    ${CardTitle} {
      color: #fff;
    }

    ${CardText} {
      color: #fffd;
    }

    ${PdfIcon} {
      filter: brightness(0) invert();
    }

    ${CsvIcon} {
      filter: brightness(0) invert();
    }
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${({ hoverBackground }) =>
    hoverBackground || 'linear-gradient(45deg,rgba(13, 131, 217, 0.9), rgba(17, 210, 219, 0.9))'};
    z-index: -1;
    transition: 200ms opacity ease;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }
`
