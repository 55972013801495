import { NavItem, Topbar } from "components/Elements/Topbar"
import { AccountMenu } from "components/UI/AccountMenu"
import { Navigate, Route, Routes } from "react-router-dom"
import { Payments } from "./Payments"
import { Plans } from "./Plans"

export const Billing = () => {
  return (
    <>
      <Topbar right={<AccountMenu />}>
        <NavItem path='plans'>plans</NavItem>
        <NavItem path='payments'>payments</NavItem>
      </Topbar>
      <Routes>
        <Route path="/plans" element={<Plans />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/" element={<Navigate to="plans" />} />
      </Routes>
    </>
  )
}
