import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../app/store'

const tags = [
  'audience',
  'contact',
  'mmsAttachment',
  'p2pAgent',
  'planType',
  'question',
  'SMSApiConfig',
  'smsBrand',
  'smsCampaign',
  'smsConversation',
  'smsMessage',
  'smsMessageTemplate',
  'subscriberList',
  'survey',
  'surveyInteraction',
  'tcrBrand',
  'vote',
]

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.bellwetherinsight.com/',
    // baseUrl: 'https://staging-api.bellwetherinsight.com/',
    // baseUrl: 'http://localhost:3001/',
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.jwtToken
      headers.set('id-token', token)
      return headers
    },
  }),
  tagTypes: tags,
  endpoints: () => ({}),
})
