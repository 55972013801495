import { useState } from 'react'
import { usePutQuestionMutation } from 'services/questionApi'
import styled from 'styled-components/macro'
import { useAutosave } from 'utils/useAutosave'
import { DesktopField, DesktopPrompt } from '../SharedDesktopQuestionComponents'
import { Tile } from '../SharedMobileQuestionComponents'

interface Props {
  question: Question
  orientation?: 'desktop' | 'mobile'
}

export const ImageRankQuestion = ({ question, orientation = 'mobile' }: Props) => {
  const [editQuestion] = usePutQuestionMutation()

  const [prompt, setPrompt] = useState(question.prompt)

  useAutosave(prompt, () => editQuestion({ _id: question._id, delta: { prompt } }), 500)

  const [rank, setRank] = useState<Choice[]>([])

  const isQuad = question.choices.length === 4

  const Choice = ({ choice }: { choice: Choice }) => {
    const ranking = rank.indexOf(choice) === -1 ? null : rank.indexOf(choice) + 1
    return (
      <ChoiceContainer
        isQuad={isQuad}
        onClick={() => {
          if (ranking) return setRank(rank.filter((eachChoice) => eachChoice !== choice))
          return setRank([...rank, choice])
        }}
      >
        {choice.image?.url && <ChoiceImage src={choice.image.url} />}
      </ChoiceContainer>
    )
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile>
        <DesktopField>
          <DesktopPrompt
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
        </DesktopField>
        <DesktopChoices>
          {question.choices.map((choice, index) => (
            <DesktopChoiceWrapper key={index}>
              {choice.label}
              <DesktopChoice
                src={choice?.image?.url}
                onClick={() => {
                  const ranking = rank.indexOf(choice) === -1 ? null : rank.indexOf(choice) + 1
                  if (ranking) return setRank(rank.filter((eachChoice) => eachChoice !== choice))
                  return setRank([...rank, choice])
                }}
              />
            </DesktopChoiceWrapper>
          ))}
        </DesktopChoices>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <Choices style={{ flexDirection: isQuad ? 'row' : 'column' }}>
          {question.choices.map((choice: any, index: any) => {
            return <Choice key={index} choice={choice} />
          })}
        </Choices>
        {question.prompt && (
          <PromptWrapper>
            <Prompt>{question.prompt}</Prompt>
          </PromptWrapper>
        )}
      </Tile>
    )
  }
}

const DesktopTile = styled.div`
  box-sizing: border-box;
  padding-top: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #008080;
  color: #ffffff;
`

const DesktopChoices = styled.div`
  display: flex;
  flex-direction: row;
`

const DesktopChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: -10px;
  color: #ffffff;
  width: 25%;
  bottom: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    background: #ffffff;
    color: #008080;
  }
`

const DesktopChoice = styled.img`
  width: 100%;
  margin-top: 10px;
`

const PromptWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  pointer-events: none;
`

const Prompt = styled.div`
  padding: 60px 30px;
  width: calc(100% - 60px);
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
`

const Choices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ChoiceContainer = styled.div<{ isQuad: boolean }>`
  width: ${({ isQuad }) => (isQuad ? '50%' : '100%')};
  margin-bottom: -4px;
  aspect-ratio: ${({ isQuad }) => (isQuad ? '0.639' : '1.279')};
  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: #08324c;
  }
`

const ChoiceImage = styled.img`
  height: 100%;
  width: 100%;
`
