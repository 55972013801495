import { GrowingTextarea } from 'components/Input/GrowingTextarea'
import styled from 'styled-components/macro'

export const Tile = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 390px;
  height: 610px;
  user-select: none;
  user-drag: none;
  z-index: 1;
  top: 0;
  background: #08324c;
`

export const Prompt = styled(GrowingTextarea)`
  margin-top: 40px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  &::placeholder {
    color: inherit;
  }
`

export const OpacityTop = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
`

export const OpacityBottom = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
`

export const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const Engagements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px 0 40px 0;
`

export const Submit = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  padding: 7px 14px;
  border: solid 2px #ffffff;
  transition: all 0.25s;
  width: 20%;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: #08324c;
  }
`

const Total = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  width: calc(100% - 80px);
`
