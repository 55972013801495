import { useEffect, useState } from 'react'

import styled from 'styled-components/macro'

interface Props {
  value: boolean
  toggle: () => void
  width?: number
  label?: string
}

export const SlideToggle = ({ value, toggle, width = 40, label }: Props) => {
  const [updatedValue, setUpdatedValue] = useState<boolean>(value)

  const toggleSwitch = () => {
    setUpdatedValue(!updatedValue)
    toggle()
  }

  useEffect(() => {
    setUpdatedValue(value)
  }, [value])

  return (
    <StyledSlideToggle width={width}>
      <Input type="checkbox" checked={updatedValue} onChange={toggleSwitch} />
      <Sliderbar selected={updatedValue} />
      <Slider selected={updatedValue} width={width} />
      {label && <SliderLabel>{label}</SliderLabel>}
    </StyledSlideToggle>
  )
}

const StyledSlideToggle = styled.label<{ width: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: ${({ width }) => width + 'px'};
  height: 24px;
`

const Input = styled.input`
  display: none;
`

const Sliderbar = styled.div<{ selected: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 32px;
  background-color: ${({ selected }) => (selected ? '#2196f3' : '#cccccc')};
  transition: 0.25s;
`

const Slider = styled.div<{ selected: boolean; width: number }>`
  position: absolute;
  height: 16px;
  width: 16px;
  left: ${({ selected, width }) => (selected ? width - 20 + 'px' : '4px')};
  bottom: 4px;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  transition: 0.25s;
`

const SliderLabel = styled.p`
  position: absolute;
  left: 50px;
  white-space: nowrap;
  cursor: pointer;
`
