import { Email, Mms, Share } from '@mui/icons-material'
import Lightbox from 'components/legacy/Lightbox'
import { Loading, MaxWidthAndCenter } from 'components/SharedPageComponents'
import { CopyText } from 'components/UI/CopyText'
import styled from 'styled-components/macro'

export const ExperienceSharingSettings = ({ experience }: { experience: any }) => {
  const getShareableLink = () => {
    // todo: handle other experience types (currently hardcoded to surveys)
    return experience.customId
      ? // ? `https://engage.bellwetherinsight.com/survey/${experience.customId}`
        `https://engage.bellwetherinsight.com/survey/${experience.customId}`
      : 'https://example.com'
  }

  if (!experience) {
    return <Loading>Loading...</Loading>
  }

  return (
    <MaxWidthAndCenter>
      <Main>
        <SectionTitle>Share</SectionTitle>
        <Section>
          <Card>
            <ShareIcon />
            <CardTitle>Sharing link</CardTitle>
            <CopyText>{getShareableLink()}</CopyText>
            <CustomizeLinkButton>Customize link</CustomizeLinkButton>
          </Card>

          <ClickableCard hoverBackground="linear-gradient(45deg,#372bb9,rgb(154,52,167))" style={{ width: 300 }}>
            <MmsIcon />
            <CardTitle>Start a Conversation</CardTitle>
            <CardText>
              Boost engagement by sending a custom graphic with a link to begin your Experience. Leverage multiple
              channels including MMS, WhatsApp and Meta Messenger.
            </CardText>
          </ClickableCard>

          <ClickableCard style={{ width: 200 }}>
            <EmailIcon />
            <CardTitle>Send in an email</CardTitle>
            <CardText>
              Generate an HTML snippet to embed in emails. Recipients can click to begin the Experience.
            </CardText>
          </ClickableCard>
        </Section>

        <SectionTitle style={{ marginTop: 70 }}>Embed</SectionTitle>
        <Section>
          <EmbedCard>
            <PopoverIcon />
            <CardTitle>Popover</CardTitle>
            <CardText>Your Experience popups up in the bottom-left of the screen</CardText>
          </EmbedCard>
          <EmbedCard>
            <DrawerIcon />
            <CardTitle>Drawer</CardTitle>
            <CardText>Your Experience slides in from the side of the page</CardText>
          </EmbedCard>
          <EmbedCard>
            <PopupIcon />
            <CardTitle>Popup</CardTitle>
            <CardText>Your Experience appears in a fullscreen popup. Launch</CardText>
          </EmbedCard>
          <EmbedCard>
            <InlineIcon />
            <CardTitle>Inline</CardTitle>
            <CardText>Your Experience appears within your webpage</CardText>
          </EmbedCard>
        </Section>
      </Main>

      <Lightbox />
    </MaxWidthAndCenter>
  )
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  padding: 40px;
`

const SectionTitle = styled.p`
  font-size: 20px;
  margin: 30px 0;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const CustomizeLinkButton = styled.p`
  color: #0d83d9;
  margin-top: auto;
  align-self: center;
  place-self: center;
  cursor: pointer;
  font-weight: 400;
  /* text-decoration: underline; */
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 150px;
  height: 140px;
  padding: 20px;
  border-radius: 2px;
  background: white;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  margin: 0 20px 20px 0;
`

const CardTitle = styled.p`
  font-size: 16px;
  color: #333;
  margin: 10px 0;
`

const CardText = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #555;
`

const ShareIcon = styled(Share)``

const MmsIcon = styled(Mms)``

const EmailIcon = styled(Email)``

const ClickableCard = styled(Card)<{ hoverBackground?: string }>`
  transition: 200ms all ease;
  z-index: 1;

  cursor: pointer;

  &:hover {
    box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px,
      rgb(0 0 0 / 2%) 0px 12px 16px;

    ${CardTitle} {
      color: #fff;
    }

    ${CardText} {
      color: #fffd;
    }

    ${ShareIcon} {
      filter: brightness(0) invert();
    }

    ${MmsIcon} {
      filter: brightness(0) invert();
    }

    ${EmailIcon} {
      filter: brightness(0) invert();
    }
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${({ hoverBackground }) =>
      hoverBackground || 'linear-gradient(45deg,rgba(13, 131, 217, 0.9), rgba(17, 210, 219, 0.9))'};
    z-index: -1;
    transition: 200ms opacity ease;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }
`

const EmbedCard = styled(ClickableCard)`
  width: 300px;
`

const PopoverIcon = () => (
  <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="29" height="27" rx="1.5" fill="white" stroke="#BBB"></rect>
    <rect x="19" y="9" width="8" height="12" rx="1" fill="#0487AF"></rect>
    <circle cx="25.5" cy="23.5" r="1.5" fill="#0487AF"></circle>
  </svg>
)

const DrawerIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="2.5" width="29" height="27" rx="1.5" fill="white" stroke="#BBB"></rect>
    <rect x="16" y="5" width="12" height="22" rx="1" fill="#0487AF"></rect>
  </svg>
)

const PopupIcon = () => (
  <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="29" height="27" rx="1.5" fill="white" stroke="#BBB"></rect>
    <rect x="6" y="6" width="18" height="16" rx="0.5" fill="#0487AF"></rect>
  </svg>
)

const InlineIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="2.5" width="29" height="27" rx="1.5" fill="white" stroke="#BBB"></rect>
    <rect x="6" y="10" width="20" height="12" rx="1" fill="#0487AF"></rect>
    <rect x="6" y="6" width="20" height="1" fill="#BBB"></rect>
    <rect x="6" y="25" width="20" height="1" fill="#BBB"></rect>
  </svg>
)
