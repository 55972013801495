import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { AuthenticationDetails, CognitoUser, CognitoUserPool, ISignUpResult } from 'amazon-cognito-identity-js'

const poolData = {
  // bwi-dev
  UserPoolId: 'us-east-1_9PSMG37dp',
  ClientId: '7dms5nf6tj04gh9m5k87lqhc8p',

  // bwi-prod
  // UserPoolId: 'us-east-1_6ThpuDHkj',
  // ClientId: '7migcl7itvc8d9jk5cr469hlvn'

  // yaynay (legacy)
  // UserPoolId: 'us-east-1_hOvMBHmZy',
  // ClientId: '2fdajtlodp40ni7tlmq08djma9',
}

const userPool = new CognitoUserPool(poolData)

export interface AuthState {
  authenticated: boolean
  existingUserError: boolean
  forgotPassword: boolean
  jwtToken: string
  loginPage: 'signUp' | 'signIn' | 'forgotPassword' | 'resetPassword' | 'confirmEmail'
  recoveryAddress: string
  recoveryUsername: string
  role: string
  status: 'idle' | 'loading' | 'failed'
  userId: string
  username?: string
  password?: string
  firstName?: string
  lastName?: string
}

const initialState: AuthState = {
  authenticated: false,
  existingUserError: false,
  forgotPassword: false,
  jwtToken: '',
  loginPage: 'signIn',
  recoveryAddress: '',
  recoveryUsername: '',
  role: 'none',
  status: 'loading',
  userId: '',
  firstName: '',
  lastName: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    forgotPasswordFlowStarted: (state) => {
      state.loginPage = 'forgotPassword'
    },
    forgotPasswordEmailSent: (state) => {
      state.loginPage = 'resetPassword'
    },
    forgotPasswordFlowEnded: (state) => {
      state.loginPage = 'signIn'
      state.recoveryAddress = ''
    },
    signUpFlowStarted: (state) => {
      state.loginPage = 'signUp'
    },
    signUpFlowCanceled: (state) => {
      state.loginPage = 'signIn'
    },
    clearCredentials: (state) => {
      state.password = undefined
      state.username = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncGetUser.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncGetUser.fulfilled, (state, action) => {
        state.status = 'idle'
        state.authenticated = !!action.payload.jwtToken
        state.jwtToken = action.payload.jwtToken || ''
        state.role = action.payload.role || 'none'
      })
      .addCase(asyncGetUser.rejected, (state) => {
        state.status = 'idle'
        state.authenticated = false
        state.jwtToken = ''
      })
      .addCase(asyncSignIn.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncSignIn.fulfilled, (state, action) => {
        state.status = 'idle'
        state.authenticated = true
        state.jwtToken = action.payload.jwtToken || ''
        state.loginPage = 'signIn'
      })
      .addCase(asyncSignIn.rejected, (state) => {
        state.status = 'failed'
        state.loginPage = 'signIn'
      })
      .addCase(asyncSignUp.fulfilled, (state, action) => {
        state.username = action.payload.username
        state.password = action.payload.password
        state.firstName = action.payload.firstName
        state.lastName = action.payload.lastName
        state.loginPage = 'confirmEmail'
      })
      .addCase(asyncForgotPassword.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncForgotPassword.fulfilled, (state, action) => {
        state.status = 'idle'
        state.forgotPassword = false
        state.recoveryUsername = action.payload.username
        state.recoveryAddress = action.payload.address as string
      })
      .addCase(asyncForgotPassword.rejected, (state) => {
        state.status = 'failed'
      })
      .addCase(asyncConfirmCodeUpdatePassword.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncConfirmCodeUpdatePassword.fulfilled, (state) => {
        state.status = 'idle'
        state.recoveryAddress = ''
        state.loginPage = 'signIn'
      })
      .addCase(asyncSignOut.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(asyncSignOut.fulfilled, (state, action) => {
        state.status = 'idle'
        state.authenticated = false
        state.jwtToken = ''
      })
  },
})

export const asyncGetUser = createAsyncThunk<{ jwtToken?: string; role?: string }>('auth/getUser', async () => {
  const { jwtToken, role } = await getUser()
  return { jwtToken, role }
})

export const asyncSignUp = createAsyncThunk<
  { success?: boolean; userId?: string; username: string; password: string; firstName: string; lastName: string },
  { username: string; password: string; firstName: string; lastName: string }
>('auth/signUp', async (credentials) => {
  const { username, password, firstName, lastName } = credentials
  const { success } = await signUp(username, password)
  if (!success) throw new Error('sign up flow failed')
  return { success, username, password, firstName, lastName }
})

export const asyncConfirmCode = createAsyncThunk<{ success: boolean }, { code: string; username: string }>(
  'auth/confirmCode',
  async (credentials) => {
    const { code, username } = credentials
    const { success } = await confirmCode(code, username)
    return { success }
  }
)

export const asyncSignIn = createAsyncThunk<{ jwtToken?: string }, { username: string; password: string }>(
  'auth/signIn',
  async (credentials) => {
    const { username, password } = credentials
    const { success, jwtToken } = await signIn(username, password)
    if (!success) throw new Error('authentication failed')
    return { jwtToken }
  }
)

export const asyncSignOut = createAsyncThunk<{ success: boolean }>('auth/signOut', async () => {
  const { success } = await signOut()
  return { success }
})

export const asyncForgotPassword = createAsyncThunk<{ address?: string; username: string }, { username: string }>(
  'auth/forgotPassword',
  async (credentials) => {
    const { username } = credentials
    const { success, address } = await forgotPassword(username)
    if (!success) throw new Error('forgot password failed')
    return { address, username }
  }
)

export const asyncConfirmCodeUpdatePassword = createAsyncThunk<
  { success: boolean },
  { username: string; code: string; newPassword: string }
>('auth/confirmCodeUpdatePassword', async (credentials) => {
  const { username, code, newPassword } = credentials
  const { success } = await confirmCodeUpdatePassword(username, code, newPassword)
  return { success }
})

const getUser = () => {
  return new Promise<{ jwtToken?: string; role?: string }>((resolve) => {
    const user = userPool.getCurrentUser()
    if (user == null) {
      resolve({ jwtToken: undefined, role: undefined })
      throw Error('no authenticated user')
    }
    user.getSession((err: any, result: any) => {
      if (err) {
        resolve({ jwtToken: undefined, role: undefined })
        throw Error(err)
      }
      resolve({ jwtToken: result.getIdToken().getJwtToken(), role: result.getIdToken().payload['custom:role'] })
    })
  })
}

const signUp = (username: string, password: string) => {
  return new Promise<{ success: boolean }>((resolve) => {
    userPool.signUp(username, password, [], [], (error?: Error, result?: ISignUpResult) => {
      if (error || !result) return resolve({ success: false })
      return resolve({ success: true })
    })
  })
}

const confirmCode = (code: string, username: string) => {
  return new Promise<{ success: boolean }>((resolve) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.confirmRegistration(code, true, (e, result) => {
      if (result === 'SUCCESS') return resolve({ success: true })
      else if (e) return resolve({ success: false })
      // @todo - add more error handling logic
      else return resolve({ success: false })
    })
  })
}

const signIn = (username: string, password: string) => {
  return new Promise<{ success: boolean; jwtToken?: string }>((resolve) => {
    const authenticationDetails = new AuthenticationDetails({ Username: username, Password: password })
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const jwtToken = result.getIdToken().getJwtToken()
        return resolve({ success: true, jwtToken: jwtToken })
      },
      onFailure: (err) => {
        return resolve({ success: false })
      },
    })
  })
}

const signOut = () => {
  return new Promise<{ success: boolean }>((resolve) => {
    const user = userPool.getCurrentUser()
    if (user == null) return resolve({ success: true })
    user.signOut(() => resolve({ success: true }))
  })
}

const forgotPassword = (username: string) => {
  return new Promise<{ success: boolean; address?: string }>((resolve) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        resolve({ success: true, address: result.CodeDeliveryDetails.Destination })
      },
      onFailure: (err) => {
        resolve({ success: false })
      },
    })
  })
}

const confirmCodeUpdatePassword = (username: string, code: string, newPassword: string) => {
  return new Promise<{ success: boolean }>((resolve) => {
    const cognitoUser = new CognitoUser({ Username: username, Pool: userPool })
    cognitoUser.confirmPassword(code, newPassword, {
      onSuccess: (result) => {
        resolve({ success: true })
      },
      onFailure: (err) => {
        resolve({ success: false })
      },
    })
  })
}


export const {
  forgotPasswordFlowStarted,
  forgotPasswordEmailSent,
  forgotPasswordFlowEnded,
  signUpFlowStarted,
  signUpFlowCanceled,
  clearCredentials,
} = authSlice.actions

