import styled from 'styled-components/macro'

export const Toggle = styled.input`
  -webkit-appearance: none;
  position: relative;
  width: 30px;
  height: 16px;
  border-radius: 25px;
  margin-right: 8px;
  background-color: #ccc;
  transition: background 0.3s;
  outline: none;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-color: rgb(255, 255, 255);
    transition: left 0.3s;
  }

  &:checked {
    background: green;
  }

  &:checked::after {
    left: 70%;
  }
`
