import { useEffect, useRef, useState } from 'react'
import { usePutQuestionMutation } from 'services/questionApi'
import styled from 'styled-components/macro'
import { useAutosave } from 'utils/useAutosave'
import { DesktopField, DesktopPrompt, DesktopTile } from '../SharedDesktopQuestionComponents'
import { Engagements, Prompt, Submit, Tile } from '../SharedMobileQuestionComponents'

interface Props {
  question: Question
  orientation?: 'desktop' | 'mobile'
}

export const RankQuestion = ({ question, orientation = 'mobile' }: Props) => {
  const [editQuestion] = usePutQuestionMutation()

  const [prompt, setPrompt] = useState(question.prompt)

  useAutosave(prompt, () => editQuestion({ _id: question._id, delta: { prompt } }), 500)

  const [rank, setRank] = useState<Choice[]>([])

  const Choice = ({ choice }: { choice: Choice }) => {
    const ranking = rank.indexOf(choice) === -1 ? null : rank.indexOf(choice) + 1
    return (
      <ChoiceContainer
        onClick={() => {
          if (ranking) return setRank(rank.filter((choice) => choice !== choice))
          return setRank([...rank, choice])
        }}
      >
        <ChoiceImageContainer>
          {choice.image?.url && <ChoiceImage src={choice.image.url} />}
          <ChoiceOverlay selected={!!ranking}>{ranking}</ChoiceOverlay>
        </ChoiceImageContainer>
        <ChoiceLabel>{choice.label}</ChoiceLabel>
      </ChoiceContainer>
    )
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile>
        <DesktopField>
          <DesktopPrompt
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
          <DesktopChoices>
            {question.choices.map((choice: any, index: any) => {
              return <Choice key={index} choice={choice} />
            })}
          </DesktopChoices>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <Prompt>{question.prompt}</Prompt>
        <Choices>
          {question.choices.map((choice: any, index: any) => {
            return <Choice key={index} choice={choice} />
          })}
        </Choices>
        <Engagements>
          <Submit>{'Submit'}</Submit>
        </Engagements>
      </Tile>
    )
  }
}

const DesktopChoices = styled.div`
  zoom: 0.7;
`

const Choices = styled.div`
  padding: 0px;
`

const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0px;
  width: calc(100% - 80px);
  padding: 6px 40px;
  color: #ffffff;
  transition: all 0.25s;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: #08324c;
  }
`

const ChoiceImageContainer = styled.div`
  position: relative;
  height: 72px;
`

const ChoiceImage = styled.img`
  align-self: center;
  height: 68px;
  width: 68px;
  min-width: 68px;
  max-width: 68px;
  border-radius: 40px;
  border: solid 2px #ffffff;
  margin: 0;
  padding: 0;
`

const ChoiceOverlay = styled.div<{ selected: boolean }>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background: ${({ selected }) => (selected ? 'rgba(255,255,255,0.7)' : 'none')};
  color: #08324c;
  font-size: 30px;
  font-weight: 800;
`

const ChoiceLabel = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-left: 20px;
`
