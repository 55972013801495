import { useEffect } from 'react'

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Account } from 'pages/Admin/Account'
import { Billing } from 'pages/Admin/Billing'
import { Users } from 'pages/Admin/Users'
import { Support } from './Admin/Support'

// import { BillingAdmin } from 'pages/Admin/BillingAdmin'

import { Developers } from './Admin/Developers'
import { Shenanigans } from './Engage/Shenanigans'
import { SurveyDetails } from './Engage/SurveyDetails'
import { Workspaces } from './Workspaces/Workspaces'

export const Dashboard = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.indexOf('/engage/') !== -1) {
      navigate(location.pathname.replaceAll('/engage/', '/experiences/'))
    }
  }, [location, navigate])

  return (
    <Panel>
      {/* <Sidebar /> */}
      <Content>
        <Routes>
          <Route path="/experiences/workspaces" element={<Workspaces />} />
          <Route path="/experiences/surveys/:surveyId/*" element={<SurveyDetails />} />
          <Route path="/playground" element={<Shenanigans />} />

          <Route path="/account/*" element={<Account />} />
          <Route path="/billing/*" element={<Billing />} />
          <Route path="/users" element={<Users />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/support" element={<Support />} />

          {/* default route */}
          <Route path="/*" element={<Navigate to="/dashboard/experiences/workspaces" />} />
        </Routes>
      </Content>
    </Panel>
  )
}

const Panel = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
const Content = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  padding-top: 50px;
  width: 100%;
  overflow-y: auto;
`
