import React from 'react'

export default class Lightbox extends React.Component {
  constructor(props) {
    super(props)
    this.state = { open: false, fabHovered: false }
  }

  render() {
    return (
      <div style={Container}>
        {this.props.fullscreen && (
          <div
            style={Object.assign({}, Backdrop, {
              width: this.state.open ? '100vw' : 0,
              height: this.state.open ? '100vh' : 0,
              backdropFilter: this.state.open ? 'blur(3px)' : 'blur(0px)',
            })}
          ></div>
        )}
        <div
          style={Object.assign({}, Create, this.state.fabHovered ? { background: '#245696' } : {})}
          onMouseEnter={() => this.setState({ fabHovered: true })}
          onMouseLeave={() => this.setState({ fabHovered: false })}
          onClick={() => this.setState({ open: !this.state.open })}
        >
          <p
            style={{
              transition: '300ms transform ease',
              transform: this.state.open ? 'rotate(135deg)' : 'rotate(0deg)',
              fontSize: 30
            }}
          >
            +
          </p>
        </div>
        <div style={!this.state.open ? Popup : Object.assign({}, Popup, { opacity: 1, transform: 'scale(1)' })}>
          <iframe style={IFrame} src="https://engage.bellwetherinsight.com/survey/ConsumerSurveyDemo" />
        </div>
      </div>
    )
  }
}

const Container = {
  position: 'fixed',
  bottom: 100,
  right: 30,
  zIndex: 999999,
}
const Backdrop = {
  position: 'fixed',
  top: 0,
  left: 0,
  background: '#fff3',
  transition: '150ms backdrop-filter ease',
}

const Create = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 32,
  fontFamily: 'Courier, monospace',
  fontWeight: 300,
  color: '#ffffff',
  position: 'fixed',
  bottom: 20,
  right: 40,
  height: 56,
  width: 56,
  borderRadius: '50%',
  background: '#0288d1',
  boxShadow:
    '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
  cursor: 'pointer',
  userSelect: 'none',
  transition: '300ms background ease',
}

const Popup = {
  position: 'relative',
  right: 0,
  bottom: 0,
  width: 400,
  height: 600,
  boxShadow:
    'rgb(0 0 0 / 20%) 0px 3px 5px -1px, rgb(0 0 0 / 14%) 0px 6px 10px 0px, rgb(0 0 0 / 12%) 0px 1px 18px 0px',
  background: 'white',
  borderRadius: 4,
  opacity: 0,
  transform: 'scale(0.7)',
  transformOrigin: 'bottom right',
  transition: '300ms all ease',
  userSelect: 'none',
}

const IFrame = {
  width: '100%',
  height: '100%',
  border: 'none',
  borderRadius: 4,
}
