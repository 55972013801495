import { Navigate, useLocation } from 'react-router-dom'
import styled, { ThemeProvider } from 'styled-components/macro'

import { ThemeContext } from 'App'
import { useAppSelector } from 'app/hooks'
import { useContext, useState } from 'react'
import { centered } from 'styles/layout'
import { storyform } from 'styles/theme'
import { ConfirmEmail } from './ConfirmEmail'
import { ForgotPassword } from './ForgotPassword'
import { ResetPassword } from './ResetPassword'
import { ShimmerField } from './Shimmer'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'

export const Login = () => {
  const { authenticated, loginPage, recoveryAddress } = useAppSelector((state) => state.auth)
  const location = useLocation()
  const { from } = (location.state as any) || { from: { pathname: '/' } }
  const [counter, setCounter] = useState(0)
  const theme = useContext(ThemeContext)

  const infoTextSubstitutions = [
    { text: 'digital engagement', fontSize: 50 },
    { text: 'customer loyalty', fontSize: 50 },
    { text: 'lead generation', fontSize: 50 },
    { text: 'product matching', fontSize: 50 },
  ]

  const loginPages = ['resetPassword', 'forgotPassword', 'signIn', 'signUp', 'confirmEmail']

  const displayedInfo = infoTextSubstitutions[counter % infoTextSubstitutions.length]

  return (
    <>
      {authenticated ? (
        <Navigate to={from.pathname} />
      ) : (
        <ThemeProvider theme={storyform[theme]}>
          <StyledLogin loaded={false}>
            <InputSection>
              <ShimmerField />
              <LoginCarousel fieldNumber={loginPages.indexOf(loginPage)}>
                <ResetPassword />
                <ForgotPassword />
                <SignIn />
                <SignUp />
                <ConfirmEmail />
              </LoginCarousel>
            </InputSection>
            <InfoSection>
              <BannerBackground>
                <Engagement>
                  the future of
                  <br />
                  <PinkText size={displayedInfo.fontSize}>{displayedInfo.text}</PinkText>
                  <br />
                  is here
                </Engagement>
              </BannerBackground>
            </InfoSection>
          </StyledLogin>
        </ThemeProvider>
      )}
    </>
  )
}

const StyledLogin = styled.div<{ loaded: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in;
  overflow: hidden;

  height: 100vh;
  width: 100vw;
`

const InputSection = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  max-width: 400px;
  border-right: 1px solid ${({theme}) => theme.centerSplit};
  background: ${({theme}) => theme.inputBackground};
`

const LoginCarousel = styled.div<{ fieldNumber: number }>`
  ${centered}
  width: 500%;
  transform: ${({ fieldNumber }) => `translateX(${-400 * fieldNumber}px)`};
  transition: 0.2s all;
`

const InfoSection = styled.div`
  position: relative;
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
`

const BannerBackground = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgb(22 94 139), rgb(129, 203, 227));
  background: ${({theme}) => theme.splashBackground};
  background-size: cover;
`

const Engagement = styled.h1`
  color: #ffffff;
  text-align: center;
  font-size: 50px;
  font-weight: 500;
`

const PinkText = styled.span<{ size: number }>`
  font-size: ${({ size }) => `${size}px`};
  color: #e814d8;
`

const CardDecks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`
