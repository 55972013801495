import styled from 'styled-components/macro'

import { SurveyQuestions } from 'pages/Engage/SurveyQuestions'
import { useState } from 'react'

import { SlideToggle } from 'components/Input/SlideToggle'
import { useDeleteSurveyMutation, usePutSurveyMutation } from 'services/surveyApi'

interface Props {
  survey: Survey
}

export const SurveyPreview = ({ survey }: Props) => {
  const [editSurvey] = usePutSurveyMutation()
  const [deleteSurvey] = useDeleteSurveyMutation()

  const [adminMenuOpen, setAdminMenuOpen] = useState<boolean>(false)
  const [flowchartOpen, setFlowchartOpen] = useState<boolean>(false)

  const togglePreviewMode = () => {
    editSurvey({ _id: survey._id!, delta: { previewMode: !survey.previewMode } })
  }

  return (
    <StyledSurveyPreview>
      <Topbar preview={survey.previewMode}>
        <Title>
          {survey.name}
          <QuestionCount>{survey?.questions?.length + ' questions'}</QuestionCount>
          {survey.previewMode && <PreviewWarning>Survey in Preview Mode (votes are untracked)</PreviewWarning>}
        </Title>
        <Actions>
          <Share onClick={() => alert('sharing dialog')}>Get shareable link</Share>
          <Gear
            open={adminMenuOpen}
            onClick={() => setAdminMenuOpen((s) => !s)}
            src="/img/legacy_icons/gear.svg"
          />
        </Actions>
        {adminMenuOpen && (
          <AdminMenu>
            <MenuLabel>Settings</MenuLabel>
            <SlideToggle label="Preview Mode" value={survey.previewMode} toggle={togglePreviewMode} />
          </AdminMenu>
        )}
      </Topbar>
      <SurveyQuestions survey={survey} />
    </StyledSurveyPreview>
  )
}

const StyledSurveyPreview = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding-bottom: 10px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0px 1px 2px 0px #3336;
`

const Topbar = styled.div<{ preview: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 10px;
  font-size: 16px;
  background-color: ${({ preview }) => (preview ? '#e86100' : '#527185')};
  color: #ffffff;

  border-radius: 4px 4px 0 0;
  transition: background-color 250ms ease;
`

const Title = styled.div`
  margin: 5px;
  font-size: 16px;
  font-weight: 600;
`

const QuestionCount = styled.span`
  margin-left: 5px;
  font-size: 14px;
  margin-left: 30px;
  font-weight: normal;
`

const PreviewWarning = styled.span`
  margin-left: 40px;
  font-size: 14px;
  font-weight: 700;
`

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Share = styled.div`
  margin: 0 15px 0 0;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  /* border: 1px solid #ffffff; */
  padding: 3px 5px;
`

const Gear = styled.img<{ open: boolean }>`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 50% 50% 0 0;
  z-index: 5;
  border: 5px solid ${({ open }) => (open ? '#ffffff' : 'transparent')};
  ${({ open }) => open && 'background-color: #ffffff'};
  ${({ open }) => !open && 'filter: brightness(0) invert(1)'}
`

const AdminMenu = styled.div`
  position: absolute;
  top: 40px;
  right: 5px;
  width: 210px;
  padding: 5px 15px 15px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  cursor: default;
  box-shadow: 0 2px 2px -1px rgb(0 0 0 / 20%), 0 4px 6px 1px rgb(0 0 0 / 14%), 0 2px 6px 2px rgb(0 0 0 / 12%);
  z-index: 4;
`

const MenuLabel = styled.div`
  margin: 10px 0;
  text-align: left;
  font-size: 12px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
`

//   handleRemoveQuestion = (questionId, confirmed) => {
//     // if (!confirmed) {
//     //   return this.context.getActions('OverlayActions')._open({
//     //     foreground: this.renderQuestionRemovalConfirmationDialog(questionId),
//     //     onBackgroundClick: this.context.getActions('OverlayActions')._close
//     //   })
//     // }
//     // confirmation has been given; remove the question from the survey
//     let filteredQuestions = [...this.props.survey.questions].filter((q) => q._id !== questionId)
//     if (this._removalConfirmationButton) {
//       this._removalConfirmationButton.innerText = 'Removing...'
//     }
//     this.props.editSurvey({
//       _id: this.props.survey._id,
//       delta: { ...this.props.survey, questions: filteredQuestions },
//     })
//     // this.context.getActions('SurveyActions')
//     // .editOne(this.props.survey._id, {
//     //   questions: filteredQuestions
//     // }, true)
//     // .then(() => {
//     //   this.context.getActions('QuestionActions').editOne(questionId, {survey: null}) // we can fire and forget since the edited question will not appear anywhere on the page, having just been removed from the survey it was in, and any nav to a page containing the removed question will update it anyway
//     //   this.context.getActions('OverlayActions')._close()
//     // })
//     // .catch(() => {
//     //   alert('Unable to remove the survey question at this time. Please check your internet connnection and try reloading the page. Contact us if this issue persists.')
//     //   this._removalConfirmationButton.innerText = 'Remove'
//     // })
//   }

//   handleSelectColor = (event) => {
//     const stateKey = event.target.name
//     const newVal = event.target.value
//     // const oldVal = this.state[stateKey]
//     this.setState({ [stateKey]: newVal })
//     if (this._saveSurveyTaskId !== -1) {
//       clearInterval(this._saveSurveyTaskId)
//     }
//     this._saveSurveyTaskId = setTimeout(() => {
//       this.props.editSurvey({ _id: this.props.survey._id, delta: { [stateKey]: newVal } })
//       // this.context.getActions('SurveyActions')
//       // .editOne(this.props.survey._id, { [stateKey]: newVal }, true)
//       // .then(this.context.getActions('OverlayActions')._close)
//       // .catch(() => {
//       //   alert('Unable to save image. Please check your internet connnection and try reloading the page. Contact us if this issue persists.')
//       //   this.setState({[stateKey]: oldVal})
//       // })
//     }, 1000)
//   }

//   handleChangePaidForBy = (event) => {
//     const newVal = event.target.value
//     const oldVal = this.state.paidForBy
//     this.setState({ paidForBy: newVal }, () => {
//       if (this._saveSurveyTaskId !== -1) {
//         clearInterval(this._saveSurveyTaskId)
//       }
//       this._saveSurveyTaskId = setTimeout(() => {
//         this.props.editSurvey({ _id: this.props.survey._id, delta: { paidForBy: newVal } }).catch(() => {
//           alert(
//             'Unable to save "Paid For By" text. Please check your internet connnection and try reloading the page. Contact us if this issue persists.'
//           )
//           this.setState({ paidForBy: oldVal })
//         })
//       }, 1000)
//       //   this.context.getActions('SurveyActions')
//       //   .editOne(this.props.survey._id, { paidForBy: newVal }, true)
//       //   .then(this.context.getActions('OverlayActions')._close)
//       //   .catch(() => {
//       //     alert('Unable to save \"Paid For By\" text. Please check your internet connnection and try reloading the page. Contact us if this issue persists.')
//       //     this.setState({ paidForBy: oldVal })
//       //   })
//       // }, 1000)
//     })
//   }

//   handleChangeBackground = (event) => {
//     const newVal = event.target.value
//     const oldVal = this.state.background
//     this.setState({ background: newVal }, () => {
//       if (this._saveSurveyTaskId !== -1) {
//         clearInterval(this._saveSurveyTaskId)
//       }
//       this._saveSurveyTaskId = setTimeout(() => {
//         this.props.editSurvey({ _id: this.props.survey._id, delta: { background: newVal } }).catch(() => {
//           alert(
//             'Unable to save your changes to the survey background. Please check your internet connnection and try reloading the page. Contact us if this issue persists.'
//           )
//           this.setState({ background: oldVal })
//         })
//       }, 1000)
//     })
//   }

//   handleDeleteSurvey = (confirmed) => {
//     this.props.deleteSurvey({ _id: this.props.survey._id })
//     // if (!confirmed) {
//     //   return this.context.getActions('OverlayActions')._open({
//     //     foreground: this.renderSurveyDeletionConfirmationDialog(),
//     //     onBackgroundClick: this.context.getActions('OverlayActions')._close
//     //   })
//     // }
//     // // confirmation has been given; delete the survey
//     // this.context.getActions('SurveyActions')
//     // .deleteOne(this.props.survey._id)
//     // .then(this.context.getActions('OverlayActions')._close)
//     // .catch(() => {
//     //   alert('This survey could not be deleted at this time. Please check your internet connnection and try reloading the page. Contact us if this issue persists.')
//     //   this._removalConfirmationButton.innerText = 'Delete'
//     // })
//   }

//   openQuestionCreator = (question, linkedSourceQuestion) =>
//     this.setState({ questionCreatorOpen: true, editingQuestion: question, linkedSourceQuestion })

//   handleCloseQuestionCreator = (refresh) => {
//     this.setState({ questionCreatorOpen: false, editingQuestion: null })
//   }

//   openSharingDialog = () => this.setState({ sharingDialogOpen: true })

//   closeSharingDialog = () => this.setState({ sharingDialogOpen: false })

//   copyShareableLink = () => {
//     navigator.clipboard.writeText(`https://engage.bellwetherinsight.com/survey/${this.props.survey.customId}`)
//     this._copySpan.textContent = 'copied!'
//     setTimeout(() => (this._copySpan.textContent = 'copy'), 1000)
//   }

//   handleUploadPhoto = (photoUrl, stateKey) => this.openPhotoCropper(photoUrl, stateKey)

//   openPhotoCropper = (photoUrl, stateKey) => {
//     if (photoUrl && stateKey) {
//       // don't open cropper if there's no photo to crop
//       this.setState({ uncroppedPhotoUrl: photoUrl, photoCropperStateKey: stateKey }) // setting photoCropperStateKey to a truthy value causes the cropper Popup to render
//     }
//   }

//   handleSaveCrop = (croppedPhotoUrl) =>
//     this.props
//       .editSurvey({
//         _id: this.props.survey._id,
//         delta: { [this.state.photoCropperStateKey]: croppedPhotoUrl },
//       })
//       .then((result) => {
//         this.setState({
//           [this.state.photoCropperStateKey]: croppedPhotoUrl,
//           uncroppedPhotoUrl: '',
//           photoCropperStateKey: '',
//         })
//       })

//   handleCancelCrop = () => this.setState({ photoCropperStateKey: '' })

//   handleRemovePhotoUrl = (stateKey) => {
//     const oldVal = this.state[stateKey]
//     this.setState({ [stateKey]: '' }, () => {
//       this.props.editSurvey({ _id: this.props.survey._id, delta: { [stateKey]: '' } }).catch(() => {
//         alert(
//           'Unable to save image. Please check your internet connnection and try reloading the page. Contact us if this issue persists.'
//         )
//         this.setState({ [stateKey]: oldVal })
//       })
//     })
//   }

//   supportsActions = (question) => ['boolean', 'multiple-choice'].indexOf(question.type) !== -1

//   openQuestionActionsConfig = (question) => this.setState({ actionConfigOpenFor: question })

//   closeQuestionActionsConfig = () => this.setState({ actionConfigOpenFor: null })

//   handleExportQuestion = (question) => {
//     alert('This feature is unavailable on demo accounts. Please contact us to upgrade your account.')
//     return
//     // alert('Please contact us to unlock this feature.')
//     // let jsonContent = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(question))
//     // var link = document.createElement('a')
//     // link.setAttribute('href', jsonContent)
//     // link.setAttribute('download', 'question_' + question._id + '.json')
//     // document.body.appendChild(link) // Required for FF

//     // link.click()
//   }

//   renderSharingOverlay = () => {
//     return (
//       <div className="survey-sharing-overlay">
//         <p className="survey-sharing-overlay-title">Share survey</p>
//         <p>
//           <span
//             style={{ fontSize: 12 }}
//           >{`https://engage.bellwetherinsight.com/survey/${this.props.survey.customId}`}</span>
//           <span
//             ref={(span) => (this._copySpan = span)}
//             className="copy-survey-link"
//             onClick={this.copyShareableLink}
//           >
//             {' copy'}
//           </span>
//         </p>
//         <p className="survey-sharing-overlay-details">
//           Anyone with this link will be able to take this survey, after logging in or signing up.{' '}
//           <span style={{ fontWeight: 'bold' }}>This link is case-sensitive.</span>
//         </p>
//       </div>
//     )
//   }

//   renderAdminMenu = () => {
//         {/* <p className="action-button" style={{backgroundColor: '#333940'}}>Deactivate survey</p> */}
//         <div className="survey-settings-toggle-wrapper">
//           <TooltipText
//             style={{ padding: 10, width: 260 }}
//             tooltip={
//               <span>
//                 Preview Mode lets you test your survey (via the shareable link) without affecting its metrics.
//                 Votes and other interactions will not be counted while your survey is in Preview Mode.
//               </span>
//             }
//             position={'left'}
//           >
//             Preview Mode
//           </TooltipText>
//           <label className="survey-settings-toggle-switch">
//             <input
//               className="survey-settings-toggle-checkbox"
//               type="checkbox"
//               checked={this.state.previewMode}
//               onChange={this.handleTogglePreviewMode}
//             />
//             <span className="survey-settings-toggle-slider"></span>
//           </label>
//         </div>
//         <p
//           className="action-button"
//           style={{ backgroundColor: '#ab2505' }}
//           onClick={() => this.handleDeleteSurvey()}
//         >
//           Delete survey
//         </p>
//       </div>
//     )
//   }

//   renderSurveyMetrics = () => {
//     if (
//       !this.props.survey.anonymousVoting ||
//       !this.props.survey.anonymousVoting.enabled ||
//       !this.props.survey.anonymousVoting.counts
//     ) {
//       return
//     }

//     let metrics = []
//     let view = this.props.survey.anonymousVoting.counts.view || 0
//     let start = this.props.survey.anonymousVoting.counts.start || 0
//     let completion = this.props.survey.anonymousVoting.counts.completion || 0

//     // metrics.push({
//     //   metric:'Questions',
//     //   value: survey.questions.length
//     // })

//     // if (!survey.anonymousVoting && survey.anonymousVoting.counts) {
//     //   return null
//     // }
//     // let anon = survey.anonymousVoting.counts

//     metrics.push(
//       {
//         metric: 'Unique views',
//         value: view,
//         description: '',
//       },
//       {
//         metric: 'Unique starts',
//         value: start,
//         description: '',
//       },
//       {
//         metric: 'Start rate',
//         value: view === 0 ? 'N/A' : Math.round((start * 1000.0) / view) / 10 + '%',
//         description:
//           'The percentage of people who started the survey (ie. voted on the first question) after viewing it',
//       },
//       {
//         metric: 'Completions',
//         value: completion,
//         description: 'The number of people who voted on all questions in the survey',
//       },
//       {
//         metric: 'Completion rate',
//         value: start === 0 ? 'N/A' : Math.round((completion * 1000.0) / start) / 10 + '%',
//         description: 'The percentage of people who finished the survey after starting it',
//         tooltipPosition: 'left',
//       }
//     )

//     // , {
//     //   metric:'CTA click rate',
//     //   value: completion === 0 || !survey.callToAction || ['donate', 'card'].indexOf(survey.callToAction.type) === -1 ? 'N/A' : (Math.round(ctaClick * 1000.0 / completion) / 10) + '%',
//     //   description: 'The number of unique clicks on the call-to-action link at the end of the survey (Donate and Card CTA modes only)',
//     //   tooltipPosition: 'left'
//     // }

//     let basicMetrics = metrics.map((metric, index) => (
//       <div key={metric.metric + index} className="survey-metric">
//         <h2>{metric.value}</h2>
//         <p>
//           {metric.description ? (
//             <TooltipText
//               style={{ padding: 10, width: 260 }}
//               tooltip={<span>{metric.description}</span>}
//               position={metric.tooltipPosition}
//             >
//               {metric.metric}
//             </TooltipText>
//           ) : (
//             metric.metric
//           )}
//         </p>
//       </div>
//     ))

//     return (
//       <div>
//         <div>{basicMetrics}</div>
//       </div>
//     )
//   }

//   renderBrandingSection = () => {
//     let { navbarImageUrl, desktopImageUrl } = this.state
//     return (
//       <div>
//         <div className="survey-cta-card-photo-wrapper">
//           <FileUploader
//             style={{ margin: '0 20px' }}
//             onUpload={(photoUrl) => this.handleUploadPhoto(photoUrl, 'navbarImageUrl')}
//           >
//             <div className="survey-cta-card-photo" style={!navbarImageUrl ? { height: 150, width: 150 } : null}>
//               <img
//                 src={navbarImageUrl || '/img/legacy_icons/camera.png'}
//                 style={navbarImageUrl ? { maxWidth: 220, maxHeight: 150 } : { width: 80 }}
//               />
//             </div>
//             <p className="survey-cta-card-photo-change" style={{ maxWidth: 200, margin: '10px auto' }}>
//               Click to {navbarImageUrl ? 'change' : 'add a'} logo (displayed in the right-hand corner of the voting
//               interface on mobile screens)
//             </p>
//           </FileUploader>
//           {navbarImageUrl && (
//             <div className="survey-cta-card-photo-remove">
//               <div
//                 className="survey-cta-card-photo-remove-btn"
//                 onClick={() => this.handleRemovePhotoUrl('navbarImageUrl')}
//               >
//                 <img src="/img/legacy_icons/minus-red.svg" />
//                 <p>Remove</p>
//               </div>
//             </div>
//           )}
//         </div>

//         <div className="survey-cta-card-photo-wrapper">
//           <FileUploader
//             style={{ margin: '0 20px' }}
//             onUpload={(photoUrl) => this.handleUploadPhoto(photoUrl, 'desktopImageUrl')}
//           >
//             <div className="survey-cta-card-photo" style={!desktopImageUrl ? { height: 150, width: 150 } : null}>
//               <img
//                 src={desktopImageUrl || '/img/legacy_icons/camera.png'}
//                 style={desktopImageUrl ? { maxWidth: 220, maxHeight: 150 } : { width: 80 }}
//               />
//             </div>
//             <p className="survey-cta-card-photo-change" style={{ maxWidth: 200, margin: '10px auto' }}>
//               Click to {desktopImageUrl ? 'change' : 'add a'} logo (displayed in the top center of the voting
//               interface on desktop screens)
//             </p>
//           </FileUploader>
//           {desktopImageUrl && (
//             <div className="survey-cta-card-photo-remove">
//               <div
//                 className="survey-cta-card-photo-remove-btn"
//                 onClick={() => this.handleRemovePhotoUrl('desktopImageUrl')}
//               >
//                 <img src="/img/legacy_icons/minus-red.svg" />
//                 <p>Remove</p>
//               </div>
//             </div>
//           )}
//         </div>

//         <div className="survey-preview-paid-for-by" style={{ marginRight: 60 }}>
//           <p>"Paid for by" text (shown in footer)</p>
//           <textarea
//             className="editor-input"
//             style={{ margin: '10px 0', width: '100%' }}
//             rows={6}
//             name="paidForBy"
//             type="text"
//             placeholder="Paid for by..."
//             value={this.state.paidForBy}
//             onChange={this.handleChangePaidForBy}
//           />
//         </div>

//         <div className="survey-preview-color-pickers" style={{ width: 220 }}>
//           <p>Voting Interface Look & Feel</p>
//           {/* <div className="survey-preview-color-picker">
//             <input
//               type="color"
//               name="navbarColor"
//               value={this.state.navbarColor}
//               onChange={this.handleSelectColor}
//             />
//             <label for="head">Top bar</label>
//           </div>
//           <div className="survey-preview-color-picker">
//             <input
//               type="color"
//               name="backgroundColor"
//               value={this.state.backgroundColor}
//               onChange={this.handleSelectColor}
//             />
//             <label for="head">Background</label>
//           </div>
//         </div>

//         <div className="survey-preview-paid-for-by" style={{ marginLeft: 60 }}>
//           <p>"Paid for by" text (shown in footer)</p>
//           <textarea
//             className="editor-input"
//             style={{ margin: '10px 0' }}
//             rows={6}
//             name="paidForBy"
//           </div> */}
//           <p style={{ textDecoration: 'none', border: 'none' }}>Background</p>
//           <input
//             className="survey-cta-input"
//             name="donateButtonUrl"
//             type="text"
//             placeholder="dodgerblue, #aaa, linear-gradient(...), url(...)"
//             value={this.state.background}
//             onChange={this.handleChangeBackground}
//           />
//         </div>
//       </div>
//     )
//   }

//   renderQuestionRemovalConfirmationDialog = (questionId) => {
//     return (
//       <div className="survey-actions-overlay no-select">
//         <p className="confirmation-prompt">
//           Please confirm removal of this survey question by clicking{' '}
//           <span style={{ fontWeight: 'bold' }}>Remove</span> below. The question will be saved in the{' '}
//           <span style={{ fontWeight: 'bold' }}>All Questions</span> page, so you can always add it back later.
//         </p>
//         <div className="action-buttons">
//           <p
//             className="action-button"
//             style={{
//               marginRight: 20,
//               backgroundColor: 'transparent',
//               color: '#333',
//               border: '1px solid #555',
//             }}
//             // onClick={this.context.getActions('OverlayActions')._close}
//           >
//             Cancel
//           </p>
//           <p
//             ref={(el) => (this._removalConfirmationButton = el)}
//             className="action-button"
//             style={{ backgroundColor: '#ab2505' }}
//             onClick={() => this.handleRemoveQuestion(questionId, true)}
//           >
//             Remove
//           </p>
//         </div>
//       </div>
//     )
//   }

//   renderSurveyDeletionConfirmationDialog = () => {
//     return (
//       <div className="survey-actions-overlay no-select">
//         <p className="confirmation-prompt">
//           Please confirm deletion of this survey by clicking <span style={{ fontWeight: 'bold' }}>Delete</span>{' '}
//           below. This action cannot be undone.
//         </p>
//         <div className="action-buttons">
//           <p
//             className="action-button"
//             style={{
//               marginRight: 20,
//               backgroundColor: 'transparent',
//               color: '#333',
//               border: '1px solid #555',
//             }}
//             // onClick={this.context.getActions('OverlayActions')._close}
//           >
//             Cancel
//           </p>
//           <p
//             ref={(el) => (this._removalConfirmationButton = el)}
//             className="action-button"
//             style={{ backgroundColor: '#ab2505' }}
//             onClick={() => this.handleDeleteSurvey(true)}
//           >
//             Delete
//           </p>
//         </div>
//       </div>
//     )
//   }

//

//

//

//

//

//   render() {

//         <Collapsible
//           title={<p className="survey-section-label">Overview</p>}
//           style={{ boxShadow: 'none' }}
//           defaultCollapsed={false}
//           expandedContentHeight={300}
//         >
//           <div className="survey-overview">{this.renderSurveyMetrics(this.props.anon)}</div>
//         </Collapsible>

//         {survey.surveyType === 'flowchart' ? (
//           <Collapsible
//             title={<p className="survey-section-label">Survey flowchart</p>}
//             style={{ boxShadow: 'none' }}
//             defaultCollapsed={false}
//             expandedContentHeight={800}
//           >
//             <div className="survey-flowchart-wrapper">
//               <p className="survey-flowchart-open-btn" onClick={this.toggleFlowchartOpen}>
//                 Open survey flowchart
//               </p>
//             </div>
//           </Collapsible>
//         ) : (
//           <Collapsible
//             title={<p className="survey-section-label">Survey questions</p>}
//             style={{ boxShadow: 'none' }}
//             defaultCollapsed={false}
//             expandedContentHeight={550}
//           >
//             <div className="survey-questions">{this.renderSurveyQuestions(survey)}</div>
//           </Collapsible>
//         )}

//         <Collapsible
//           title={
//             !this.state.ctaBranchingEnabled ? (
//               <p className="survey-section-label">Call-to-Action</p>
//             ) : (
//               <p className="survey-section-label">
//                 <span>Primary Call-to-Action</span>
//                 <span style={{ marginLeft: 2, color: 'gray' }}>
//                   {' '}
//                   ({survey.ctaBranching.matchThreshold} or more vote matches)
//                 </span>
//                 <span
//                   style={{
//                     float: 'right',
//                     marginRight: 15,
//                     color: '#002868',
//                     textDecoration: 'underline',
//                     textTransform: 'none',
//                     letterSpacing: 'normal',
//                     cursor: 'pointer',
//                   }}
//                   onClick={this.openCTABranchingEditor}
//                 >
//                   Configure CTA Branching
//                 </span>
//               </p>
//             )
//           }
//           style={{ boxShadow: 'none' }}
//           defaultCollapsed={true}
//           expandedContentHeight={550}
//         >
//           <SurveyCTAEditor survey={survey} editSurvey={this.props.editSurvey} />
//         </Collapsible>

//         <Collapsible
//           title={<p className="survey-section-label">Branding & Disclaimers</p>}
//           style={{ boxShadow: 'none' }}
//           defaultCollapsed={true}
//           expandedContentHeight={550}
//         >
//           {this.renderBrandingSection()}
//         </Collapsible>

//         <Collapsible
//           title={<p className="survey-section-label">Distribution</p>}
//           style={{ boxShadow: 'none' }}
//           defaultCollapsed={true}
//           expandedContentHeight={550}
//         >
//           <SurveyDistributionEditor survey={survey} editSurvey={this.props.editSurvey} />
//         </Collapsible>

//         <NavLink
//           to={'/dashboard/surveys/reports/' + survey._id}
//           style={{ textDecoration: 'none', color: 'white' }}
//         >
//           <div
//             className="survey-preview-nav-btn"
//             // onClick={() =>
//             //   alert('This feature is unavailable on demo accounts. Please contact us to upgrade your account.')
//             // }
//           >
//             <p className="survey-section-label" style={{ color: 'white' }}>
//               Generate report
//             </p>
//             <p className="survey-preview-footer-arrow">›</p>
//           </div>
//         </NavLink>
//         {/* <Link href={'/surveys/' + survey.customId + '/messaging'}>
//           <div className="survey-preview-nav-btn">
//             <p className="survey-section-label" style={{color: 'white'}}>Invitations and Follow-up Messaging</p>
//             <p className="survey-preview-footer-arrow">›</p>
//           </div>
//           <div className="survey-preview-nav-btn-locked">
//             <p className="survey-section-label" style={{color: 'white'}}>Invitations and Follow-up Messaging</p>
//             <p className="survey-preview-footer-arrow">›</p>
//           </div>
//         </Link> */}

//         {this.state.questionCreatorOpen && (
//           <Popup style={{ left: survey.surveyType === 'linear' ? 230 : 0, zIndex: 99999 }}>
//             <div
//               style={{
//                 background: 'white',
//                 margin: '100px 40px 40px',
//                 height: 'calc(100% - 140px)',
//                 overflow: 'auto',
//                 borderRadius: 30,
//               }}
//             >
//               <QuestionCreator
//                 survey={survey}
//                 question={this.state.editingQuestion}
//                 linkedSourceQuestion={this.state.linkedSourceQuestion}
//                 onCancel={this.handleCloseQuestionCreator}
//               />
//             </div>
//           </Popup>
//         )}

//         {this.state.sharingDialogOpen && (
//           <Popup style={{ left: 280 }} handleBackgroundClick={this.closeSharingDialog}>
//             <div className="survey-sharing-overlay">
//               <p className="survey-sharing-overlay-title">Share survey</p>
//               <p>
//                 <span
//                   style={{ fontSize: 12 }}
//                 >{`https://engage.bellwetherinsight.com/survey/${this.props.survey.customId}`}</span>
//                 <span
//                   ref={(span) => (this._copySpan = span)}
//                   className="copy-survey-link"
//                   onClick={this.copyShareableLink}
//                 >
//                   {' copy'}
//                 </span>
//               </p>
//               <p className="survey-sharing-overlay-details">
//                 Anyone with this link will be able to take this survey, after logging in or signing up.{' '}
//                 <span style={{ fontWeight: 'bold' }}>This link is case-sensitive.</span>
//               </p>
//               <p style={{ marginBottom: 20, cursor: 'pointer' }} onClick={this.closeSharingDialog}>
//                 Close
//               </p>
//             </div>
//           </Popup>
//         )}

//         {!!this.state.photoCropperStateKey && (
//           <Popup
//             style={{ left: survey.surveyType === 'linear' ? 230 : 0, zIndex: 99999 }}
//             onBackgroundClick={this.handleCancelCrop}
//           >
//             <div className="photo-cropper-overlay">
//               <PhotoCropper
//                 src={this.state.uncroppedPhotoUrl}
//                 onSave={(croppedPhotoUrl) => this.handleSaveCrop(croppedPhotoUrl, this.state.photoCropperStateKey)}
//                 onCancel={this.handleCancelCrop}
//               />
//             </div>
//           </Popup>
//         )}

//         {this.state.actionConfigOpenFor && (
//           <Popup
//             style={{ left: survey.surveyType === 'linear' ? 230 : 0, zIndex: 99999 }}
//             onBackgroundClick={this.closeQuestionActionsConfig}
//           >
//             <div className="question-actions-editor-overlay">
//               <QuestionActionsEditor
//                 survey={survey}
//                 question={this.state.actionConfigOpenFor}
//                 onClose={this.closeQuestionActionsConfig}
//               />
//             </div>
//           </Popup>
//         )}
//       </div>
//     )
//   }
// }
