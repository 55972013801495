import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NewCampaignState {
  activeStep: number
  campaignName: string
  campaignType: 'a2p' | 'p2p'
  brand?: SMSBrand
  dedicatedAgents?: boolean
  audience?: Audience
  messageTemplate?: SMSMessageTemplate
  replies?: SMSMessageTemplate[]
}

const initialState: NewCampaignState = {
  activeStep: 0,
  campaignName: '',
  campaignType: 'p2p',
}

export const newCampaignSlice = createSlice({
  name: 'newCampaign',
  initialState,
  reducers: {
    activeStepSet: (state: NewCampaignState, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    campaignNamed: (state: NewCampaignState, action: PayloadAction<string>) => {
      state.campaignName = action.payload
    },
    brandSelected: (state: NewCampaignState, action: PayloadAction<SMSBrand>) => {
      state.brand = action.payload
    },
    campaignTypeSelected: (state: NewCampaignState, action: PayloadAction<'a2p' | 'p2p'>) => {
      state.campaignType = action.payload
    },
    dedicatedAgentsSelected: (state: NewCampaignState, action: PayloadAction<boolean>) => {
      state.dedicatedAgents = action.payload
    },
    audienceSelected: (state: NewCampaignState, action: PayloadAction<Audience | undefined>) => {
      state.audience = action.payload
    },
    initialMessageSelected: (state: NewCampaignState, action: PayloadAction<SMSMessageTemplate | undefined>) => {
      state.messageTemplate = action.payload
    },
    repliesSelected: (state: NewCampaignState, action: PayloadAction<SMSMessageTemplate[] | undefined>) => {
      state.replies = action.payload
    },
    campaignCreationInitialized: (state: NewCampaignState) => {},
  },
})

export const {
  activeStepSet,
  campaignNamed,
  brandSelected,
  campaignTypeSelected,
  dedicatedAgentsSelected,
  audienceSelected,
  initialMessageSelected,
  repliesSelected,
} = newCampaignSlice.actions
