import { TooltipText } from 'components/TooltipText';
import styled from 'styled-components';

export const ExperienceMetrics = ({ experience, style }: { experience: any; style?: any }) => {
  const survey = experience as Survey

  if (!survey.anonymousVoting || !survey.anonymousVoting.enabled || !survey.anonymousVoting.counts) {
    return <p>Metrics not supported for this Experience</p>
  }

  let metrics = []
  let view = survey.anonymousVoting.counts.view || 0
  let start = survey.anonymousVoting.counts.start || 0
  let completion = survey.anonymousVoting.counts.completion || 0

  // metrics.push({
  //   metric:'Questions',
  //   value: survey.questions.length
  // })

  // if (!survey.anonymousVoting && survey.anonymousVoting.counts) {
  //   return null
  // }
  // let anon = survey.anonymousVoting.counts

  metrics.push(
    {
      metric: 'Unique views',
      value: view,
      description: '',
    },
    {
      metric: 'Unique starts',
      value: start,
      description: '',
    },
    {
      metric: 'Start rate',
      value: view === 0 ? 'N/A' : Math.round((start * 1000.0) / view) / 10 + '%',
      description:
        'The percentage of people who started the survey (ie. voted on the first question) after viewing it',
    },
    {
      metric: 'Completions',
      value: completion,
      description: 'The number of people who voted on all questions in the survey',
    },
    {
      metric: 'Completion rate',
      value: start === 0 ? 'N/A' : Math.round((completion * 1000.0) / start) / 10 + '%',
      description: 'The percentage of people who finished the survey after starting it',
      tooltipPosition: 'left',
    }
  )

  // , {
  //   metric:'CTA click rate',
  //   value: completion === 0 || !survey.callToAction || ['donate', 'card'].indexOf(survey.callToAction.type) === -1 ? 'N/A' : (Math.round(ctaClick * 1000.0 / completion) / 10) + '%',
  //   description: 'The number of unique clicks on the call-to-action link at the end of the survey (Donate and Card CTA modes only)',
  //   tooltipPosition: 'left'
  // }

  let basicMetrics = metrics.map((metric, index) => (
    <ExperienceMetric key={metric.metric + index}>
      <MetricValue>{metric.value}</MetricValue>
      <MetricLabel style={metric.description ? { borderBottom: '1px dotted #000' } : {}}>
        {metric.description ? (
          <TooltipText
            style={{ padding: 10, width: 260 }}
            text={metric.description}
            position={metric.tooltipPosition as 'left' | 'right'}
          >
            {metric.metric}
          </TooltipText>
        ) : (
          metric.metric
        )}
      </MetricLabel>
    </ExperienceMetric>
  ))

  return <StyledExperienceMetrics style={style}>{basicMetrics}</StyledExperienceMetrics>
}

const StyledExperienceMetrics = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding: 20px;
  background-color: white;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`

const ExperienceMetric = styled.div`
  margin: 20px;
`

const MetricValue = styled.div`
  text-align: center;
  color: #002868;
  font-weight: normal;
  margin: 0 0 5px 0;
  font-size: 24px;
`

const MetricLabel = styled.div`
  margin: 0;
`
