import styled from 'styled-components/macro'
import {
  Background,
  Engagements,
  OpacityBottom,
  OpacityTop,
  Prompt,
  Tile,
} from '../SharedMobileQuestionComponents'

export function RateResult(props: { question: any }) {
  const { votes } = props.question
  const total = votes?.total || 0
  const voteCounts = ['0', '1', '2', '3', '4'].map((value) => {
    return { value: parseInt(value), count: votes && votes[value] ? votes[value] : 0 }
  })
  let sum = 0
  if (total) {
    voteCounts.forEach((votePair) => {
      sum += votePair.value * votePair.count
    })
  }

  const average = total ? sum / total / 4 : 0.5

  return (
    <Tile>
      <OpacityTop>
        <Prompt>{props.question.prompt}</Prompt>
      </OpacityTop>
      <OpacityBottom>
        <Engagements style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Total>{total} total votes</Total>
          <Slider />
          <RateLabels>
            <p style={{ textAlign: 'left', width: '35%%', marginRight: '15%' }}>
              {props.question.choices[0]?.label || ''}
            </p>
            <p style={{ textAlign: 'right', width: '35%%', marginLeft: '15%' }}>
              {props.question.choices[1]?.label || ''}
            </p>
          </RateLabels>
        </Engagements>
      </OpacityBottom>
      <Background src={props.question.image?.url} />
    </Tile>
  )

  function Slider() {
    return (
      <SlideBar value={average}>
        <Selector value={average} />
      </SlideBar>
    )
  }
}

const Total = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 30px;
  width: calc(100% - 80px);
`

const SlideBar = styled.div<{ value: number }>`
  height: 10px;
  border-radius: 5px;
  width: calc(100% - 80px);
  margin-bottom: 20px;
  background: linear-gradient(90deg, #0000ff 1%, #e2ccdf 45%, #e5b7c7 55%, #ff0000 100%);
  z-index: 2;
`

const Selector = styled.div<{ value: number }>`
  height: 30px;
  width: 30px;
  margin-left: calc(${({ value }) => value * 100 + '%'} - 15px);
  margin-top: -10px;
  background: #ffffff;
  border-radius: 15px;
`

const RateLabels = styled.div`
  width: calc(100% - 80px);
  font-weight: 900;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
`
