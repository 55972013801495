import { Popup } from 'components/Popup'
import { Plans } from 'pages/Admin/Billing/Plans'
import styled from 'styled-components/macro'
import { fillAbsolutely } from 'styles/layout'

interface Props {
  handleClose: () => void
}

export const Upgrade = ({ handleClose }: Props) => {
  return (
    <Popup
      handleBackgroundClick={handleClose}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <StyledUpgrade>
        <FancyHeader>Team up on Storyforms</FancyHeader>
        <Text>
          Your Basic plan includes <b style={{ color: '#555' }}>1 user</b> (you!)
        </Text>
        <Text>Please upgrade your account to invite collaborators to your workspace</Text>
        <Plans />
        <CancelButton onClick={handleClose}>Maybe later</CancelButton>
      </StyledUpgrade>
    </Popup>
  )
}

const FancyHeader = styled.p`
  font-size: 24px;
  font-weight: 600;
  user-select: none;
  font-family: 'Montserrat';
  text-decoration: none;

  background-image: linear-gradient(45deg, rgba(17, 210, 219, 0.9), rgba(13, 131, 217, 0.9));
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
`

const StyledUpgradeB = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
  padding: 20px;
`

const StyledUpgrade = styled.div`
  position: relative;
  margin: 20px;
  padding: 20px;
  box-sizing: border-box;
  background: linear-gradient(45deg, rgba(17, 210, 219, 1), rgba(13, 131, 217, 1));
  border-radius: 8px;
  user-select: none;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  --angle: 0deg;
  animation: 5s rotate linear infinite;
  text-align: center;
  overflow: hidden;
  z-index: 1;

  &:before {
    ${fillAbsolutely}
    box-sizing: border-box;
    border-radius: 7px;
    margin: 1px;
    background: #ffffff;
    z-index: -1;
    content: '';
  }
`

const Text = styled.div`
  color: black;
  margin: 20px;
`

const CancelButton = styled.div`
  margin-top: 10px;
  color: #777;
  font-size: 12px;
  cursor: pointer;
`
