import styled from 'styled-components/macro'

export const ColorPicker = ({
  children,
  label,
  defaultValue,
  onChange,
}: {
  children?: any
  label?: string
  defaultValue?: string
  onChange?: any
}) => (
  <StyledColorPicker>
    <InputWrapper>
      <Input
        type="color"
        defaultValue={defaultValue || '#ffffff'}
        onChange={(event) => onChange(event.target.value)}
      />
    </InputWrapper>
    {children || <ColorPickerLabel>{label || 'Pick a color'}</ColorPickerLabel>}
  </StyledColorPicker>
)

const StyledColorPicker = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  width: 120px;
`

const InputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid #aaaa;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 8%) 0px 2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px;
`

const Input = styled.input`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100px;
  width: 100px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const ColorPickerLabel = styled.p`
  margin: 10px 2px;
  font-size: 12px;
  font-weight: 600;
  color: #555555;
  padding: 4px 8px;
`
