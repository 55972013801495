import { useRef, useState } from 'react'

import styled from 'styled-components/macro'

import {
  CloseRounded,
  CodeRounded,
  CreditCardRounded,
  GroupsRounded,
  HelpCenterRounded,
  LogoutRounded,
  PersonOutlineRounded
} from '@mui/icons-material'
import { useAppDispatch } from 'app/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { asyncSignOut } from 'services/authSlice'
import { baseApi } from 'services/baseApi'
import { useClickOutside } from 'utils/useClickOutside'

export const AccountMenu = ({ }: {}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const isConversations = pathname.indexOf('/conversations/') !== -1 // todo: convert to useAppSelector

  const [menuOpen, setMenuOpen] = useState(false)

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  useClickOutside(wrapperRef, () => setMenuOpen(false))

  return (
    <MenuOpenButton
      onClick={() => setMenuOpen(true)}
      style={!isConversations ? {} : { background: 'linear-gradient(45deg,#372bb9,#9a34a7)' }}
    >
      <p style={{ fontWeight: 600, color: '#fff' }}>JH</p>

      <MenuCloseButton
        style={!isConversations ? {} : { background: '#dbbbf3' }}
        menuOpen={menuOpen}
        onClick={(event) => {
          event.stopPropagation()
          setMenuOpen(false)
        }}
      >
        {menuOpen && <CloseRounded sx={{ color: '#fff' }} />}
      </MenuCloseButton>

      {menuOpen && (
        <>
          <MenuPopover
            ref={wrapperRef}
            style={!isConversations ? {} : { background: 'linear-gradient(45deg,#372bb9,#9a34a7)' }}
          >
            <MenuGrid>
              <MenuItem onClick={() => navigate('/dashboard/account/personal')}>
                <PersonOutlineRounded />
                <MenuLabel>Account</MenuLabel>
              </MenuItem>
              <MenuItem onClick={() => navigate('/dashboard/billing/plans')}>
                <CreditCardRounded />
                <MenuLabel>Billing</MenuLabel>
              </MenuItem>
              <MenuItem onClick={() => navigate('/dashboard/users')}>
                <GroupsRounded />
                <MenuLabel>Users</MenuLabel>
              </MenuItem>
              <MenuItem onClick={() => navigate('/dashboard/developers')}>
                <CodeRounded />
                <MenuLabel>Developers</MenuLabel>
              </MenuItem>
              <MenuItem onClick={() => navigate('/dashboard/support')}>
                <HelpCenterRounded />
                <MenuLabel>Support</MenuLabel>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(baseApi.util.resetApiState())
                  dispatch(asyncSignOut())
                }}
              >
                <LogoutRounded
                  sx={{
                    // color: '#a44848'
                    color: 'white',
                  }}
                />
                <MenuLabel
                  style={{
                    // color: '#a44848'
                    color: 'white',
                  }}
                >
                  Sign out
                </MenuLabel>
              </MenuItem>
            </MenuGrid>
          </MenuPopover>
        </>
      )}
    </MenuOpenButton>
  )
}

const MenuOpenButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: linear-gradient(45deg, rgb(17, 210, 219), rgb(13, 131, 217));
  cursor: pointer;
  user-select: none;
  border: solid 1px #333333;
  box-sizing: border-box;
  
`

const MenuCloseButton = styled.div<{ menuOpen: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #bbe0f3;
  width: 100%;
  height: 100%;
  transition: 200ms opacity ease;
  border-radius: inherit;
  cursor: pointer;

  width: ${({ menuOpen }) => (menuOpen ? '100%' : 0)};
  height: ${({ menuOpen }) => (menuOpen ? '100%' : 0)};
  opacity: ${({ menuOpen }) => (menuOpen ? 1 : 0)};
`

const MenuPopover = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  top: 40px;
  right: 0;
  /* width: 340px; */
  width: 240px;
  /* height: 200px; */
  background: #fafafa;
  z-index: 200;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  /* box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.4); */
  box-shadow: rgb(0 0 0 / 8%) 0px -2px 4px, rgb(0 0 0 / 6%) 0px 2px 12px, rgb(0 0 0 / 4%) 0px 8px 14px,
    rgb(0 0 0 / 2%) 0px 12px 16px;

  background: linear-gradient(45deg, rgba(13, 131, 217, 1), rgba(17, 210, 219, 1));
  color: white;
  cursor: default;
`

const MenuTitle = styled.p`
  font-size: 14px;
  margin: 20px auto;
  color: white;
`

const MenuGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
`

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 0;
  transition: 200ms all ease;
  margin: 10px;
  cursor: pointer;

  &:hover {
    background: #fff3;
    border-radius: 20px;
  }
`

const MenuLabel = styled.p`
  margin-top: 4px;
  color: #fff;
  font-size: 12px;
`
