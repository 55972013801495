import { useEffect, useState } from 'react'

import styled from 'styled-components/macro'

import Reorder, { reorder } from 'react-reorder'
import { usePutSurveyMutation } from 'services/surveyApi'
import { Question } from '../../components/Question/QuestionPreview/Question'

interface Props {
  survey: Survey
}

export const SurveyQuestions = ({ survey }: Props) => {
  const [editSurvey] = usePutSurveyMutation()
  const [surveyQuestions, setSurveyQuestions] = useState<Question[]>(survey.questions || [])
  const [locked, setLocked] = useState<boolean>(false)

  const reorderQuestions = (event: any, previousIndex: any, nextIndex: any, fromId: any, toId: any) => {
    const questions = reorder([...surveyQuestions], previousIndex, nextIndex)
    setSurveyQuestions(questions)
    editSurvey({ _id: survey._id!, delta: { questions: questions } })
  }

  const earlyExits = (question: Question) => {
    if (!survey.earlyExits) return []
    return survey.earlyExits[question._id!] || []
  }

  useEffect(() => {
    setSurveyQuestions(survey.questions || [])
  }, [survey.questions])

  return (
    <StyledSurveyQuestions>
      <Reorder
        reorderId={'survey-' + survey._id + '-reorder'}
        component="div"
        style={{ display: 'inline-block', verticalAlign: 'top', marginTop: 0 }}
        lock="vertical"
        holdTime={0}
        touchHoldTime={150}
        onReorder={reorderQuestions}
        autoScroll={true}
        disabled={locked}
        disableContextMenus={true}
        placeholder={<Placeholder />}
      >
        {surveyQuestions.map((question, index) => {
          question = Object.assign({ survey: survey }, question)
          return (
            <SurveyQuestion key={index}>
              <SurveyQuestionFader>
                <Question question={question} />
                {earlyExits(question).length > 0 && (
                  <EarlyExit>
                    <EarlyExitBadge src="/img/legacy_icons/stop.png" />
                    {earlyExits(question).length + ' early exit condition(s)'}
                  </EarlyExit>
                )}
                <Actions onMouseEnter={() => setLocked(true)} onMouseLeave={() => setLocked(false)}>
                  <Action>
                    <ActionIcon src="/img/legacy_icons/pencil.png" />
                    <ActionLabel>Modify</ActionLabel>
                  </Action>
                  <Action>
                    <ActionIcon src="/img/legacy_icons/stop.png" />
                    <ActionLabel>Early Exit</ActionLabel>
                  </Action>
                  <Action>
                    <ActionIcon src="/img/legacy_icons/folder.png" />
                    <ActionLabel>Export</ActionLabel>
                  </Action>
                  <Action>
                    <ActionIcon src="/img/legacy_icons/trash.png" />
                    <ActionLabel>Remove</ActionLabel>
                  </Action>
                </Actions>
              </SurveyQuestionFader>
            </SurveyQuestion>
          )
        })}
      </Reorder>
    </StyledSurveyQuestions>
  )
}

const StyledSurveyQuestions = styled.div`
  white-space: nowrap;
  user-select: none;
  overflow-x: auto;
`

const Placeholder = styled.div`
  background-color: transparent;
  border: 4px dashed #777777;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 610px;
  margin: 20px 20px 90px 20px;
  user-select: none;
  vertical-align: top;
  white-space: normal;
  width: 390px;
  zoom: 0.7;
`

const SurveyQuestion = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 20px 20px 90px 20px;
  width: 390px;
  height: 610px;
  white-space: normal;
  box-shadow: 0 2px 2px -1px rgb(0 0 0 / 20%), 0 4px 6px 1px rgb(0 0 0 / 14%), 0 2px 6px 2px rgb(0 0 0 / 12%);
  z-index: 5;
  width: 273px;
  height: 427px;
  cursor: move;
`

const CreateQuestion = styled.div`
  width: 100%;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #08324c, #527185);
  color: white;
  cursor: pointer;
  z-index: 5;
`
const SurveyQuestionFader = styled.div`
  position: relative;
  zoom: 0.7;
`

const Plus = styled.div`
  font-size: 50px;
`

const AddQuestion = styled.div`
  font-size: 14px;
  font-weight: 600;
`

const EarlyExit = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 390px;
  margin: 20px 0;
`

const EarlyExitBadge = styled.img`
  width: 40px;
  vertical-align: middle;
  margin-right: 10px;
`

const Actions = styled.div`
  width: 100%;
  text-align: center;
  /* opacity: 0; */
  transition: 200ms opacity ease;
  zoom: 1.4; /* to counteract parent zoom: 0.7 */
`

const Action = styled.div`
  display: inline-block;
  vertical-align: top;
  width: max-content;
  min-width: 58px;
  margin: 10px 4px;
  cursor: pointer;
`

const ActionIcon = styled.img`
  width: 20px;
  height: 20px;
`

const ActionLabel = styled.div`
  margin: 0;
  font-size: 12px;
  color: #555;
`
