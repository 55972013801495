import styled from 'styled-components/macro'
import {
  Background,
  Engagements,
  OpacityBottom,
  OpacityTop,
  Prompt,
  Tile,
} from '../SharedMobileQuestionComponents'

export function TextResult(props: { question: any }) {
  const { votes } = props.question
  const total = votes?.total || 0
  const responses = votes['-1']

  return (
    <Tile>
      <OpacityTop>
        <Prompt>{props.question.prompt}</Prompt>
      </OpacityTop>
      <OpacityBottom>
        <Engagements>
          <Total>
            {responses} responses <span style={{ color: '#fffc' }}>({total - responses} skips)</span>{' '}
          </Total>
        </Engagements>
      </OpacityBottom>
      <Background src={props.question.image.url} />
    </Tile>
  )
}

const Total = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  width: calc(100% - 80px);
`
