export const storyform = {
  light: {
    centerSplit: '#0f5372',
    inputBackground: '#ffffff',
    splashBackgroundColor: 'linear-gradient(45deg, #165e8b, #81cbe3)',
    splashBackground: 'url("/img/branding/splash_light.png")',
    engagement: '#0f5372',
    engagementAlt: '#e814d8',
    inputLogo: 'url("/img/branding/stars_light.png")',
    inputHeader: '#1b113f',
    inputSubheader: '#0f5372',
    inputPlaceholder: '#0f5372',
    inputActive: '#1b113f',
    inputEntering: '#ff6400',
    inputValid: '#00ba00',
    subaction: '#0f5372',
    subactionFocus: '#1b113f',
    submit: '#0f5372',
    submitText: '#ffffff',
    submitHover: '#1b113f',
  },
  dark: {
    centerSplit: '#afc8d8',
    inputBackground: 'linear-gradient(#0f5372, #1b113f)',
    splashBackgroundColor: 'linear-gradient(45deg, #165e8b, #81cbe3)',
    splashBackground: 'url("/img/branding/splash_dark.png")',
    engagement: '#ffffff',
    engagementAlt: '#e814d8',
    inputLogo: 'url("/img/branding/stars_dark.png")',
    inputHeader: '#ffffff',
    inputSubheader: '#ffffff',
    inputPlaceholder: '#afc8d8',
    inputActive: '#ffffff',
    inputEntering: '#ff6400',
    inputValid: '#00ba00',
    subaction: '#afc8d8',
    subactionFocus: '#ffffff',
    submit: '#afc8d8',
    submitText: '#322172',
    submitHover: '#ffffff',
  },
}
