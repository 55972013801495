import { createContext, useEffect, useState } from 'react'

import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom'

// import LogRocket from 'logrocket'

import { Dashboard } from './pages/Dashboard'
import { Login } from './pages/Login/Login'

import { Docs } from 'docs/Docs'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { asyncGetUser } from './services/authSlice'

export const ThemeContext = createContext<'light' | 'dark'>('light')

export default function App() {
  // LogRocket.init('bouqw1/dashboard')
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(asyncGetUser())
  }, [dispatch])

  const [mode, setMode] = useState<'light' | 'dark'>('light')

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setMode('dark')
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      const colorScheme = event.matches ? 'dark' : 'light'
      setMode(colorScheme)
    })
  }, [])

  return (
    <ThemeContext.Provider value={mode}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/docs" element={<Docs />} />
          <Route
            path="/dashboard/*"
            element={
              <Authenticator>
                <Dashboard />
              </Authenticator>
            }
          />
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </Router>
    </ThemeContext.Provider>
  )
}

function Authenticator({ children }: { children: JSX.Element }) {
  const pending = useAppSelector((state) => state.auth.status) === 'loading'
  const authenticated = useAppSelector((state) => state.auth.authenticated)
  const location = useLocation()

  if (pending) return <p>Loading</p>
  else if (authenticated) return children
  else return <Navigate to="/login" state={{ from: location }} />
}
