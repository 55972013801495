import { baseApi } from './baseApi'

const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<User, void>({
      query: () => ({
        url: `/`,
        method: 'GET',
      }),
      providesTags: ['user'],
    }),

    createClient: build.mutation({
      query: ({ firstName, lastName, email }: { firstName?: string; lastName?: string; email: string }) => ({
        url: `stripe/customer`,
        method: 'POST',
        body: { firstName, lastName, email },
      }),
    }),

    getPlan: build.query<any, void>({
      query: () => ({
        url: `stripe/billingPlan`,
        method: 'GET',
      }),
      providesTags: ['planType'],
    }),

    updatePlan: build.mutation<any, any>({
      query: (planType) => ({
        url: `stripe/billingPlan`,
        method: 'PUT',
        body: { planType },
      }),
      invalidatesTags: ['planType'],
    }),

    getClientSecret: build.query<any, any>({
      query: (price) => ({
        url: `stripe/secret/${price}`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetUserQuery,
  useCreateClientMutation,
  useGetPlanQuery,
  useUpdatePlanMutation,
  useGetClientSecretQuery,
} = api
