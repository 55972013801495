import styled from 'styled-components/macro'

export const Carousel = styled.div<{ fieldNumber: number }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  transform: ${({ fieldNumber }) => `translateX(${-250 * fieldNumber}px)`};
  transition: 0.2s all;
`

export const CarouselClip = styled.div`
  width: 250px;
  overflow: hidden;
`

export const CarouselPage = styled.div`
  width: 250px;
  min-width: 250px;
  max-width: 250px;
`
