import { baseApi } from './baseApi';

const api = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSurveys: build.query<Survey[], void>({
      query: () => ({
        url: `survey/`,
        method: 'GET',
      }),
      providesTags: ['survey', 'question'],
    }),

    getSurvey: build.query<Survey, string>({
      query: (_id) => ({
        url: `survey/full/${_id}`,
        method: 'GET',
      }),
      providesTags: ['survey', 'question'],
    }),

    getSurveyRankQuestionAggregates: build.query<any, string>({
      query: (_id) => ({
        url: `survey/${_id}/rankQuestionAggregates`,
        method: 'GET',
      }),
    }),

    getSurveyTextQuestionResponses: build.query<any, string>({
      query: (_id) => ({
        url: `survey/${_id}/export-votes`,
        method: 'GET',
      }),
    }),

    postSurvey: build.mutation<Survey, Partial<Survey>>({
      query: (survey) => ({
        url: `survey/`,
        method: 'POST',
        body: survey,
      }),
      invalidatesTags: ['survey'],
    }),

    putSurvey: build.mutation<Survey, { _id: string; delta: Partial<Survey> }>({
      query: ({ _id, delta }) => ({
        url: `survey/${_id}`,
        method: 'PUT',
        body: delta,
      }),
      invalidatesTags: ['survey', 'workspace', 'question'],
    }),

    deleteSurvey: build.mutation<void, { _id: string }>({
      query: ({ _id }) => ({
        url: `survey/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['survey', 'workspace'],
    }),

    getVotes: build.query<Vote[], string>({
      query: (questionId) => ({
        url: `vote/${questionId}`,
        method: 'GET',
      }),
      providesTags: ['vote'],
    }),

    getSurveyInteractions: build.query<SurveyInteraction[], string>({
      query: (surveyId) => ({
        url: `surveyInteraction/${surveyId}`,
        method: 'GET',
      }),
      providesTags: ['surveyInteraction'],
    }),
  }),
})

export const {
  useGetSurveysQuery,
  useGetSurveyQuery,
  usePostSurveyMutation,
  usePutSurveyMutation,
  useDeleteSurveyMutation,
} = api
export const { useGetVotesQuery } = api
export const { useGetSurveyInteractionsQuery } = api
export const { useGetSurveyRankQuestionAggregatesQuery, useGetSurveyTextQuestionResponsesQuery } = api
