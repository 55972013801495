import { useEffect, useState } from 'react'

import styled from 'styled-components/macro'

import { useResizeDetector } from 'react-resize-detector'

import { useAppSelector } from 'app/hooks'
import { DeviceSelector } from 'components/Elements/DeviceSelector'
import { PhotoEditor } from 'components/ImageHandling/PhotoEditor'
import { Question } from 'components/Question/QuestionPreview/Question'
import { usePutSurveyMutation } from 'services/surveyApi'
import { centered } from 'styles/layout'
import { LeftBar } from './LeftBar/LeftBar'
import { RightBar } from './RightBar/RightBar'

export const ExperienceCanvas = ({ experience }: { experience: any }) => {
  const survey = experience as Partial<Survey>
  const [stagingSurvey, setStagingSurvey] = useState<Partial<Survey> | undefined>(survey)

  const [putSurvey] = usePutSurveyMutation()
  const [updated, setUpdated] = useState<boolean>(false)

  const photoEditorOpen = useAppSelector((state) => state.photoEditor.open)

  const [orientation, setOrientation] = useState<'desktop' | 'mobile'>('desktop')
  const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(null)

  const [lefthandSidebarOpen, setLefthandSidebarOpen] = useState(true)
  const [righthandSidebarOpen, setRighthandSidebarOpen] = useState(true)

  const {
    width: resizeObserverWidth,
    height: resizeObserverHeight,
    ref: resizeObserverRef,
  } = useResizeDetector({
    refreshMode: 'throttle',
    refreshRate: 50,
  })

  useEffect(() => {
    if (!!survey && (!stagingSurvey || survey?.updatedAt! > stagingSurvey?.updatedAt!)) {
      setStagingSurvey(survey)
      if (!selectedQuestion && survey?.questions?.length! > 0) setSelectedQuestion(survey.questions![0])
      setUpdated(true)
    }
  }, [survey, setStagingSurvey])

  useEffect(() => {
    if (survey?._id && !!stagingSurvey && stagingSurvey?.updatedAt === survey?.updatedAt && !updated) {
      putSurvey({ _id: survey._id, delta: stagingSurvey })
    }
    setUpdated(false)
  }, [stagingSurvey])

  const toggleSidebarOpen = (position: 'left' | 'right') => {
    if (position === 'left') {
      setLefthandSidebarOpen(!lefthandSidebarOpen)
    } else {
      setRighthandSidebarOpen(!righthandSidebarOpen)
    }
  }

  const isDesktop = orientation === 'desktop'

  return (
    <StyledExperienceCanvas>
      {photoEditorOpen && selectedQuestion && (
        <PhotoEditor
          image={selectedQuestion.image?.url || `${window.location.origin}/img/image_handling/add_image.png`}
        />
      )}
      <LeftBar
        experience={experience}
        open={lefthandSidebarOpen}
        onToggleOpen={() => toggleSidebarOpen('left')}
        selectedQuestion={selectedQuestion}
        onSelectQuestion={setSelectedQuestion}
        setStagingSurvey={setStagingSurvey}
      />

      <Canvas>
        {selectedQuestion && (
          <>
            <ResizeDetector ref={resizeObserverRef} ratio={isDesktop ? 16 / 9 : 39 / 61}>
              <ZoomWrapper
                style={{
                  transform: `scale(${!resizeObserverWidth ? 1 : Math.min((resizeObserverWidth || 0) / (isDesktop ? 1000 : 390), 1)
                    })`,
                }}
              >
                <AspectRatio ratio={isDesktop ? 16 / 9 : 39 / 61} style={{ width: isDesktop ? 1000 : 390 }}>
                  <Question question={selectedQuestion} orientation={orientation} stats={false} />
                </AspectRatio>
              </ZoomWrapper>
            </ResizeDetector>
            <DeviceSelector orientation={orientation} setOrientation={setOrientation} />
          </>
        )}
      </Canvas>

      <RightBar
        experience={experience}
        open={righthandSidebarOpen}
        onToggleOpen={() => toggleSidebarOpen('right')}
        selectedQuestion={selectedQuestion}
        onSelectQuestion={setSelectedQuestion}
        setStagingSurvey={setStagingSurvey}
      />
    </StyledExperienceCanvas>
  )
}

const StyledExperienceCanvas = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex: 1;
  height: 100%;
`
const Canvas = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  height: 100%;
  overflow: auto;
`

const AspectRatio = styled.div<{ ratio: number }>`
  ${({ ratio }) => (ratio >= 1 ? 'width: 90%' : 'height: 80%')};
  aspect-ratio: ${({ ratio }) => ratio};
`

const ResizeDetector = styled(AspectRatio)`
  ${centered}
  flex-direction: column;
  width: max-content;
  height: max-content;
  max-width: calc(90%);
  max-height: calc(90%);
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
`

const ZoomWrapper = styled.div`
  ${centered}
  flex-direction: row;
  transform-origin: center center;
  max-width: 1000px;
  aspect-ratio: inherit;
  background: #3333;
  transition: 200ms transform ease;
`
