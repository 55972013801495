import { Carousel, CarouselClip, CarouselPage } from 'components/Elements/Carousel'
import { underlinedNavItem } from 'components/Elements/navStyles'
import { Sidebar, SidebarDivision } from 'components/Elements/Sidebar'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { DesignTab } from './DesignTab'
import { QuestionTab } from './QuestionTab'
import { SettingsTab } from './SettingsTab'

interface Props {
  experience: Partial<Survey>
  open: boolean
  onToggleOpen: any
  selectedQuestion: any
  onSelectQuestion: any
  setStagingSurvey: any
}

export const RightBar = ({
  experience,
  open,
  onToggleOpen,
  selectedQuestion,
  onSelectQuestion,
  setStagingSurvey, // todo: revisit this because the pass-down for direct call is a different paradigm than the pass-down of callback handlers so the business logic can stay upstairs
}: Props) => {
  const survey = experience as Partial<Survey>

  const sidebarSections = ['question', 'design', 'settings']
  const [sidebarSection, setSidebarSection] = useState<'question' | 'design' | 'settings'>('question')

  const Tabs = () => (
    <SidebarDivision>
      {['Question', 'Design', 'Settings'].map((tabName, index) => {
        tabName = tabName.toLowerCase()
        const selected = sidebarSection === tabName
        return (
          <NavItem
            key={index}
            selected={selected}
            onClick={selected ? undefined : () => setSidebarSection(tabName as 'question' | 'design' | 'settings')}
          >
            {tabName}
          </NavItem>
        )
      })}
    </SidebarDivision>
  )

  return (
    <Sidebar position="right" expanded={!!open} onToggleOpen={onToggleOpen}>
      <Tabs />
      <CarouselClip>
        <Carousel fieldNumber={sidebarSections.indexOf(sidebarSection)}>
          <CarouselPage>
            <QuestionTab survey={survey} selectedQuestion={selectedQuestion} onSelectQuestion={onSelectQuestion} />
          </CarouselPage>
          <CarouselPage>
            <DesignTab />
          </CarouselPage>
          <CarouselPage>
            <SettingsTab />
          </CarouselPage>
        </Carousel>
      </CarouselClip>
    </Sidebar>
  )
}

const NavItem = styled.div<{ selected: boolean }>`
  ${underlinedNavItem}
  margin: 0 10px;
  height: 40px;
  font-size: 12px;
`
