import { MoreHoriz } from '@mui/icons-material'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeleteSurveyMutation, usePutSurveyMutation } from 'services/surveyApi'
import styled from 'styled-components/macro'
import { WorkspaceGridItem } from './WorkspaceGridItem'

export function ExperiencesGridItem({ experience, onDragStart }: { experience: any; onDragStart?: any }) {
  const navigate = useNavigate()

  const [putExperience] = usePutSurveyMutation()
  const [deleteExperience] = useDeleteSurveyMutation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMoreClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (action: 'copy-link' | 'rename' | 'delete') => {
    // todo: item-specific logic
    switch (action) {
      case 'copy-link':
        navigator.clipboard.writeText(`https://engage.bellwetherinsight.com/survey/${experience.customId}`)
        alert('Sharable link copied to clipboard!')
        break
      case 'rename':
        putExperience({
          _id: experience._id,
          delta: {
            name: window.prompt('What should this experience to be called?', experience.name) || experience.name!,
          },
        })
        break
      case 'delete':
        window.confirm('Are you sure you want to delete this experience?') &&
          deleteExperience({ _id: experience._id })
        break
      default:
        break
    }
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openExperienceDetails = () => {
    navigate('/dashboard/experiences/surveys/' + experience._id + '/create')
  }

  return (
    <WorkspaceGridItem onDragStart={onDragStart}>
      <ToggleExperienceDetails onClick={openExperienceDetails}>
        <ExperienceName>{experience.name}</ExperienceName>
      </ToggleExperienceDetails>
      <Footer>
        <p style={{ color: '#555' }}>No responses</p>
        <MoreHoriz sx={{ cursor: 'pointer', color: '#888' }} onClick={handleMoreClick} />
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ borderRadius: '8px' }}>
          <MenuItem onClick={() => handleMenuItemClick('copy-link')}>Copy Link</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('rename')}>Rename</MenuItem>
          <MenuItem onClick={() => handleMenuItemClick('delete')} sx={{ color: '#a11' }}>
            Delete
          </MenuItem>
        </Menu>
      </Footer>
    </WorkspaceGridItem>
  )
}

const ToggleExperienceDetails = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url(/img/login_bg.jpeg);
  border-radius: 8px 8px 0 0;
  cursor: pointer;
`

const ExperienceName = styled.p`
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  padding: 10px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60;
  bottom: 0;
  padding: 15px 10px;
  background-color: white;
  border-radius: 0 0 8px 8px;
`

const FooterText = styled.div`
  color: #555;
  font-size: 12px;
`
