import { css } from 'styled-components'

export const underlinedNavItem = css<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  padding: 18px 2px;
  box-sizing: border-box;
  height: 50px;
  font-weight: 600;
  transition: 0.2s all;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  color: ${({ selected }) => (selected ? '#222222' : '#888888')};
  border-bottom: 2px solid ${({ selected }) => (selected ? '#222222' : 'transparent')};
  &:hover {
    border-bottom: 2px solid ${({ selected }) => (selected ? '#222222' : '#666666')};
    color: ${({ selected }) => (selected ? '#222222' : '#666666')};
  }
`
