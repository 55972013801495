import { useAppDispatch } from 'app/hooks'
import { useState } from 'react'
import { photoEditorOpened } from 'services/photoEditorSlice'
import { usePutQuestionMutation } from 'services/questionApi'
import styled from 'styled-components/macro'
import { useAutosave } from 'utils/useAutosave'

import {
  DesktopEngagements,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile,
} from '../SharedDesktopQuestionComponents'
import { Background, Prompt, Tile } from '../SharedMobileQuestionComponents'

interface Props {
  question: Question
  orientation?: 'desktop' | 'mobile'
}

export function BooleanQuestion({ question, orientation = 'mobile' }: Props) {
  const [editQuestion] = usePutQuestionMutation()
  const [prompt, setPrompt] = useState(question.prompt)

  const dispatch = useAppDispatch()

  useAutosave(prompt, () => editQuestion({ _id: question._id, delta: { prompt } }), 500)

  if (orientation === 'desktop') {
    return (
      <DesktopTile>
        <DesktopImage
          onClick={() => dispatch(photoEditorOpened(question._id!))}
          src={question.image?.url || `${window.location.origin}/img/image_handling/add_image.png`}
        />
        <DesktopField>
          <DesktopPrompt
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
          <DesktopEngagements>
            <DesktopEngagement>Yes</DesktopEngagement>
            <DesktopEngagement>No</DesktopEngagement>
            <DesktopEngagement>Not sure</DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <OpacityTop>
          <Prompt
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          >
            {question.prompt}
          </Prompt>
        </OpacityTop>
        <OpacityBottom>
          <Engagements>
            <Engagement src={'/img/engagement_icons/no.png'} />
            <Engagement src={'/img/engagement_icons/not-sure.png'} />
            <Engagement src={'/img/engagement_icons/yes.png'} />
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const OpacityTop = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
`

const OpacityBottom = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
`

const Engagements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 40px 40px 20px 40px;
`

const Engagement = styled.img`
  height: 70px;
  cursor: pointer;
  user-select: none;
  user-drag: none;
`

const DesktopEngagement = styled.div`
  font-size: 16px;
  padding: 10px 20px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600;
  margin: 10px;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`
