import styled from 'styled-components/macro'
import { Background, OpacityBottom, OpacityTop, Prompt, Tile } from '../SharedMobileQuestionComponents'

export function BooleanResult(props: { question: any }) {
  const { votes } = props.question
  const total = votes?.total || 0
  const no = !votes ? 0 : votes['0'] || 0
  const not_sure = !votes ? 0 : votes['1'] || 0
  const yes = !votes ? 0 : votes['2'] || 0

  return (
    <Tile>
      <OpacityTop>
        <Prompt>{props.question.prompt}</Prompt>
      </OpacityTop>
      <OpacityBottom>
        <OpacityBottomFast />
        <OpacityBottomSlow>
          <Metrics>
            {total} total votes
            <Metric>
              <MetricIcon src={'/img/metric_icons/yes.png'} />
              <MetricBar width={yes / (total || 1)} color={'#6BFF78'}>
                <MetricTally width={yes / (total || 1)}>{Math.round((yes * 100) / (total || 1))}%</MetricTally>
              </MetricBar>
            </Metric>
            <Metric>
              <MetricIcon src={'/img/metric_icons/not-sure.png'} />
              <MetricBar width={not_sure / (total || 1)} color={'#FFCC00'}>
                <MetricTally width={not_sure / (total || 1)}>
                  {Math.round((not_sure * 100) / (total || 1))}%
                </MetricTally>
              </MetricBar>
            </Metric>
            <Metric>
              <MetricIcon src={'/img/metric_icons/no.png'} />
              <MetricBar width={no / (total || 1)} color={'#FF5252'}>
                <MetricTally width={no / (total || 1)}>{Math.round((no * 100) / (total || 1))}%</MetricTally>
              </MetricBar>
            </Metric>
          </Metrics>
        </OpacityBottomSlow>
      </OpacityBottom>
      <Background src={props.question.image.url} />
    </Tile>
  )
}

const Metrics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  padding: 0 40px 40px 40px;
`

const Metric = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0 0 0;
`

const MetricIcon = styled.img`
  width: 26px;
  height: 26px;
`

const MetricBar = styled.div<{ width: number }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: ${(props) => `${props.width * 100}%`};
  background: ${(props) => props.color};
  margin-left: 10px;
  border-radius: 3px;
`

const MetricTally = styled.span<{ width: number }>`
  left: ${(props) => (props.width >= 0.15 ? 'unset' : 'calc(100% + 10px)')};
  color: ${(props) => (props.width >= 0.15 ? 'rgba(0,0,0,0.7)' : 'white')};
  position: ${(props) => (props.width >= 0.15 ? 'relative' : 'absolute')};
`

const QuestionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

const OpacityBottomFast = styled.div`
  width: 100%;
  height: 40px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
`

const OpacityBottomSlow = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1));
`
