import styled from 'styled-components'
import { CardDeckSelector } from './CardDeckSelector'

export const CardShenanigans = ({ background }: { background?: string }) => {
  return (
    <StylishShenanigans>
      <Background image={background} />
      <Header>Pick the AFC division clinchers:</Header>
      <CardDecks>
        <CardDeckSelector prompt="AFC North" />
        <CardDeckSelector prompt="AFC South" />
        <CardDeckSelector prompt="AFC East" />
        <CardDeckSelector prompt="AFC West" />
      </CardDecks>
      <Footer>Hover to explore & click to choose</Footer>
    </StylishShenanigans>
  )
}

const StylishShenanigans = styled.div``

const Header = styled.h1`
  position: relative;
  margin: 40px auto;
  text-align: center;
  color: white;
  text-shadow: 0px 0px 6px #000a;
`

const Footer = styled.p`
  position: fixed;
  text-align: center;
  bottom: 40px;
  width: 100%;
  color: white;
  text-shadow: 0 0 4px black;
`

const CardDecks = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const Background = styled.div<{ image?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(3px);

  background: ${({ image }) => (image ? `url(${image})` : 'unset')};
  background-size: cover;
`
