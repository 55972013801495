import styled from 'styled-components/macro'
import { Prompt, Tile } from '../SharedMobileQuestionComponents'

export function MultipleChoiceResult(props: { question: any }) {
  const total = props.question.votes?.total || 0
  const choices_array = props.question.choices.map((choice: any) => choice)
  const choices_with_votes = choices_array.map((choice: any) => {
    return {
      value: choice.value,
      label: choice.label,
      votes: (!props.question.votes ? 0 : props.question.votes['' + choice.value]) || 0,
    }
  })

  return (
    <Tile>
      <Prompt>{props.question.prompt}</Prompt>
      <Metrics>
        {total} total votes
        {choices_with_votes.map((choice: { label: string; value: number; votes: number }) => {
          const ratio = total === 0 ? 0 : Math.round((choice.votes * 100) / total)
          return (
            <Metric key={choice.label}>
              <MetricInfo>
                <MetricName>{choice.label}</MetricName>
                <MetricValue>{ratio}%</MetricValue>
              </MetricInfo>
              <MetricBar width={ratio / 100} />
            </Metric>
          )
        })}
      </Metrics>
    </Tile>
  )
}

const Metrics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
  padding: 0 40px 40px 40px;
`

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0 0 0;
`

const MetricInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const MetricName = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
`

const MetricValue = styled.div`
  font-weight: 500;
  color: #ffffff;
  font-size: 18px;
`

const MetricBar = styled.div<{ width: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: ${(props) => `${props.width * 100}%`};
  background: #ffffff;
  border-radius: 3px;
  margin-top: 5px;
`
