import styled, { css } from 'styled-components/macro'

export const GrowingTextarea = (props: any) => {
  return (
    <InputSizer className={props.className} data-value={props.value + '#'}>
      <Textarea rows={1} {...props} />
    </InputSizer>
  )
}

const CommonSizingSettings = css`
  width: auto;
  min-width: 1em;
  grid-area: 1/1;
  margin: 0;
  resize: none;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-size: inherit;
`

const InputSizer = styled.label`
  display: inline-grid;
  vertical-align: top;
  justify-content: stretch;
  align-items: stretch;
  position: relative;
  width: 100%;
  margin: 5px;
  padding: 5px;
  box-sizing: border-box;
  font-size: inherit;
  border: 1px solid transparent;
  transition: 300ms border ease;
  border-radius: 10px;

  &:hover {
    border: 1px solid #ddd7;
  }

  ::after {
    content: attr(data-value);
    visibility: hidden;
    white-space: pre-wrap;
    ${CommonSizingSettings}
  }
`

const Textarea = styled.textarea`
  all: unset;
  word-break: break-word;
  ${CommonSizingSettings}

  cursor: pointer;

  &:focus {
    cursor: initial;
  }
`
