import { useState } from 'react'
import { usePutQuestionMutation } from 'services/questionApi'
import styled from 'styled-components/macro'
import { useAutosave } from 'utils/useAutosave'
import {
  DesktopEngagements,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile,
} from '../SharedDesktopQuestionComponents'

import {
  Background,
  Engagements,
  OpacityBottom,
  OpacityTop,
  Prompt,
  Submit,
  Tile,
} from '../SharedMobileQuestionComponents'

interface Props {
  question: Question
  orientation?: 'desktop' | 'mobile'
}

export const TextQuestion = ({ question, orientation = 'mobile' }: Props) => {
  const [editQuestion] = usePutQuestionMutation()

  const [prompt, setPrompt] = useState(question.prompt)

  useAutosave(prompt, () => editQuestion({ _id: question._id, delta: { prompt } }), 500)

  if (orientation === 'desktop') {
    return (
      <DesktopTile>
        <DesktopImage src={question.image?.url} />
        <DesktopField>
          <DesktopPrompt
            placeholder="What would you like to ask?"
            value={prompt}
            onChange={(event: any) => setPrompt(event.target.value)}
          />
          <DesktopTextArea placeholder={'Type your thoughts here...'} />
          <DesktopEngagements>
            <DesktopEngagement>Submit</DesktopEngagement>
            <DesktopEngagement>Skip</DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <OpacityTop>
          <Prompt>{question.prompt}</Prompt>
        </OpacityTop>
        <TextInput placeholder={'Type your thoughts here...'} />
        <OpacityBottom>
          <Engagements style={{ marginRight: '20px', marginLeft: '20px' }}>
            <Submit style={{ textAlign: 'center' }}>Skip</Submit>
            <Submit style={{ textAlign: 'center' }}>Submit</Submit>
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const DesktopTextArea = styled.textarea`
  box-sizing: border-box;
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  font-family: Montserrat;
  font-size: 20px;
  height: 240px;
  font-weight: 500;
  color: #008080;
  background: rgba(255, 255, 255, 0.9);
  // background: rgba(0,0,0,0.8);
  outline: none;
  border: none;
  resize: none;
`

const DesktopEngagement = styled.div`
  font-size: 16px;
  padding: 10px 20px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600;
  margin: 10px;

  &:hover {
    background: rgba(255, 255, 255, 0.4);
  }
`

const TextInput = styled.textarea`
  width: calc(100% - 80px);
  margin-left: 20px;
  padding: 20px;
  font-family: Montserrat;
  font-size: 20px;
  height: 240px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.8);
  // background: rgba(0,0,0,0.8);
  outline: none;
  border: none;
  resize: none;
`
