import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { useGetSurveyQuery } from 'services/surveyApi'

import { NavItem, Topbar } from 'components/Elements/Topbar'
import { Loading } from 'components/SharedPageComponents'
import { AccountMenu } from 'components/UI/AccountMenu'
import { ExperienceCanvas } from './ExperienceCanvas/'
import { ExperienceIntegrations } from './ExperienceIntegrations'
import { ExperienceResults } from './ExperienceResults'
import { ExperienceSharingSettings } from './ExperienceSharingSettings'

export const SurveyDetails = () => {
  const { surveyId } = useParams()
  const { data: survey } = useGetSurveyQuery(surveyId || skipToken)

  const nav = [
    { label: 'create', path: '/create' },
    { label: 'connect', path: '/connect' },
    { label: 'share', path: '/share' },
    { label: 'results', path: '/results' },
  ]

  if (!survey) {
    return <Loading>Loading...</Loading>
  }

  const basePath = `/dashboard/experiences/surveys/${survey?._id}`

  return (
    <Page>
      <Topbar right={<AccountMenu />}>
        <NavItem path={`${basePath}/create`}>create</NavItem>
        <NavItem path={`${basePath}/connect`}>connect</NavItem>
        <NavItem path={`${basePath}/share`}>share</NavItem>
        <NavItem path={`${basePath}/results`}>results</NavItem>
      </Topbar>

      <MainArea>
        <Routes>
          <Route path="/create" element={<ExperienceCanvas experience={survey} />} />
          <Route path="/connect" element={<ExperienceIntegrations experience={survey} />} />
          <Route path="/share" element={<ExperienceSharingSettings experience={survey} />} />
          <Route path="/results" element={<ExperienceResults experience={survey} />} />
          <Route
            path="/*"
            element={<Navigate to={'/dashboard/experiences/surveys/' + survey?._id + '/create'} />}
          />
        </Routes>
      </MainArea>
    </Page>
  )
}

const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
  height: calc(100vh - 50px);
  color: #08324c;
  background-color: #f7f7f7;
  user-select: none;
  overflow-y: scroll;
  z-index: 11;
`

const MainArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex: 1;
  height: 100%;
`

const ViewSelector = styled.div`
  display: flex;
  align-self: flex-end;
  background: blue;
`

const ViewType = styled.div`
  background: red;
`
