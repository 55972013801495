import styled from 'styled-components/macro'

import { centered, fillContainer } from 'styles/layout'

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  height: calc(100vh - 40px);
  color: #08324c;
  background-color: #f7f7f7;
  padding: 20px;
  user-select: none;
  overflow-y: scroll;
`

export const MaxWidthAndCenter = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  max-width: 1200px;
  margin: 0 auto;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-size: 20px;
  font-weight: 600;
  color: #1b587a;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 40px;
`

export const Subheader = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #607c8e;
  margin-bottom: 20px;
  width: 70%;
`

export const Input = styled.input`
  margin-top: 10px;
  padding: 5px 10px;
  color: #08324c;
  background: #ffffff;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: none;
  width: 75%;
`

export const PageAction = styled.div<{ disabled?: boolean }>`
  position: absolute;
  right: 40px;
  bottom: 80px;
  background: ${({ disabled }) => (disabled ? '#c4c1c8' : '#08324c')};
  padding: 8px 20px;
  color: #ffffff;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const Create = styled.div`
  ${centered}
  font-size: 32px;
  font-weight: 300;
  color: #ffffff;
  position: fixed;
  bottom: 20px;
  right: 40px;
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background: #0288d1;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
  user-select: none;
  transition: 0.5s background-color;

  &:hover {
    background: #245696;
  }
`

export const Loading = styled.div`
  ${centered}
  ${fillContainer}
  background: #ffffff;
  color: #777a;
  font-size: 26px;
  font-weight: 500;
  z-index: 999;
`
