import { Delete, Image } from '@mui/icons-material'
import Button from '@mui/material/Button'
import { Toggle } from 'components/Input/Toggle'
import { useDeleteQuestionMutation, usePostQuestionMutation } from 'services/questionApi'
import { usePutSurveyMutation } from 'services/surveyApi'
import { BottomTray, InputHeader, InputWrapper } from './components'

interface Props {
  survey: Partial<Survey>
  selectedQuestion: any
  onSelectQuestion: any
}

export const QuestionTab = ({ survey, selectedQuestion, onSelectQuestion }: Props) => {
  const [putSurvey] = usePutSurveyMutation()
  const [postQuestion, { isLoading: questionPostIsLoading, data: postedQuestion }] = usePostQuestionMutation()
  const [deleteQuestion] = useDeleteQuestionMutation()

  const fullyDeleteQuestion = (questionId: string) => {
    putSurvey({
      _id: survey._id!,
      delta: {
        questions: survey.questions?.filter((currentQuestion) => currentQuestion._id !== questionId),
      },
    })
    deleteQuestion({ _id: questionId })
  }

  return (
    <>
      <InputWrapper>
        <InputHeader>Required</InputHeader>
        <Toggle type="checkbox" />
      </InputWrapper>
      {['multiple-choice', 'image-choice', 'rank', 'image-rank'].includes(selectedQuestion?.type!) && (
        <InputWrapper>
          <InputHeader>Randomize Choices</InputHeader>
          <Toggle type="checkbox" />
        </InputWrapper>
      )}
      {['multiple-choice'].includes(selectedQuestion?.type!) && (
        <InputWrapper>
          <InputHeader>Include Other</InputHeader>
          <Toggle type="checkbox" />
        </InputWrapper>
      )}

      <Button
        onClick={() => alert('This feature is unavailable on demo accounts')}
        size="small" 
        sx={{ fontSize: '13px', color: '#444a', margin: '10px 5px', textTransform: 'none' }}
        startIcon={<Image />}
        variant="text"
      >
        Images and video
      </Button>

      <BottomTray>
        <Button
          variant="contained"
          sx={{
            width: 'calc(100% - 40px)',
            boxSizing: 'border-box',
            margin: '10px 20px',
            textAlign: 'center',
            fontSize: '12px',
            textTransform: 'none',
            backgroundColor: '#cc9797',
          }}
          startIcon={
            <Delete
              sx={{
                color: 'white',
              }}
            />
          }
          color="error"
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this question?')) {
              fullyDeleteQuestion(selectedQuestion._id)
              onSelectQuestion(null)
            }
          }}
        >
          Delete this question
        </Button>
      </BottomTray>
    </>
  )
}
